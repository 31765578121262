<template>
  <div :style="containerSize">
    <svg :width="settings.width" :height="settings.height">
      <g>
        <powerup-hierarchy-link
          v-for="(link, linkIndex) in links"
          :link="link"
          :key="`${link.target.id}${linkIndex}`"
          :node-width="nodeWidth"
          :node-height="nodeHeight"
          :link-stroke="linkStroke"
          :link-stroke-width="linkStrokeWidth"
        />
      </g>
      <g>
        <powerup-hierarchy-node
          v-for="(node, nodeIndex) in nodes"
          :key="`${node.id}${nodeIndex}`"
          :node="node"
          :node-width="nodeWidth"
          :node-height="nodeHeight"
          @click.native="select(node)"
        />
      </g>
    </svg>
  </div>
</template>

<script>
import * as d3 from 'd3';
import PowerupHierarchyLink from './PowerupHierarchyLink.vue';
import PowerupHierarchyNode from './PowerupHierarchyNode.vue';
import Utils from '@/js/utils.js';

export default {
  name: 'PowerupHierarchyGraph',
  props: {
    width: {
      type: Number,
    },
    height: {
      type: Number,
    },
    horizontal: {
      type: Boolean,
      default: true,
    },
    nodeWidth: {
      type: Number,
      default: 280,
    },
    nodeHeight: {
      type: Number,
      default: 36,
    },
    nodeGapY: {
      type: Number,
      default: 30,
    },
    nodeGapX: {
      type: Number,
      default: 40,
    },
    linkStroke: {
      type: String,
      default: '#e1e9f0',
    },
    linkStrokeWidth: {
      type: Number,
      default: 2,
    },
    treeData: {
      type: [String, Array],
      default: '',
    },
  },
  components: {
    PowerupHierarchyLink,
    PowerupHierarchyNode,
  },
  mixins: [Utils],
  data() {
    return {
      results: [],
      settings: {
        width: 1000,
        height: 500,
      },
      nodes: [],
      links: [],
    };
  },
  computed: {
    containerSize() {
      let width = this.width || this.settings.width + 20;
      let height = this.height || this.settings.height + 20;
      return {
        width: `${width}px`,
        height: `${height}px`,
      };
    },
  },
  methods: {
    swap(node) {
      let tmp = node.x;
      node.x = node.y;
      node.y = tmp;
    },
    scaleX(nodes) {
      let min = d3.min(nodes, node => node.x);
      let max = d3.max(nodes, node => node.x);
      let maxLevel = 0;
      for (let node in nodes) {
        if (nodes[node].data.level > maxLevel) {
          maxLevel = nodes[node].data.level;
        }
      }
      let width = this.nodeGapY * 10 * maxLevel;
      let scaleLinear = d3.scaleLinear().domain([min, max]).range([0, width]);
      return {
        width: width + this.nodeWidth,
        scaleLinearX: scaleLinear,
      };
    },
    scaleY(nodes) {
      let min = d3.min(nodes, node => node.y);
      let max = d3.max(nodes, node => node.y);
      let height = this.nodeGapX * nodes.map(node => node.y).length;
      let scaleLinear = d3.scaleLinear().domain([min, max]).range([0, height]);
      return {
        height: height + this.nodeHeight,
        scaleLinearY: scaleLinear,
      };
    },
    draw() {
      let hierarchy;
      hierarchy = d3
        .stratify()
        .id(d => d.name)
        .parentId(d => d.parent)(this.treeData);
      let tree = d3.tree().size([100, 100]);
      hierarchy = tree(hierarchy);
      if (this.horizontal) {
        hierarchy.each(this.swap);
      }
      let nodes = hierarchy.descendants();
      let links = hierarchy.links();
      let { width, scaleLinearX } = this.scaleX(nodes);
      let { height, scaleLinearY } = this.scaleY(nodes);
      hierarchy.each(node => {
        node.x = scaleLinearX(node.x);
        node.y = scaleLinearY(node.y);
      });
      this.nodes = nodes;
      this.links = links;
      this.settings.width = width;
      this.settings.height = height;
    },
    async select(node) {
      this.routeToPage('PowerupHierarchy', {}, { conceptId: node.data.term });
      node.data.clicked = true;
      this.$emit('change', {
        idx: node.data.level,
        value: node.data.term,
        name: node.data.name,
        isEnd: node.data.isEndNode,
      });
    },
  },
  watch: {
    treeData() {
      this.drawTree();
    },
    horizontal() {
      this.drawTree();
    },
  },
  mounted() {
    this.drawTree = this.draw;
    this.drawTree();
  },
};
</script>
