
import Vue from 'vue';
import Logger from 'Utils/Logger';
import UIApplicationHelper from 'Utils/UIApplicationHelper';
import HTMLUtilities from 'Utils/HTMLUtilities';
import { log, sendReadyMessage } from '@/js/powerup'
import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid';
import MdsSection from '@mds/section';

export default Vue.extend({
  name: 'AuthorDisclosureView',
  components: {
    MdsLayoutGrid,
    MdsRow,
    MdsCol,
    MdsSection,
  },
  data() {
    return {
      logger: {} as Logger,
      /** query identifier in the URL (...view?p=...) This is the encoded taxonomy object. */
      p: '',
      /** query identifier in the URL (...view?k=...) This is the ARC identifier ID. */
      k: '',
      /** if true, this will output console messages */
      debug: false,
      /** true if an error occurs */
      pageHasError: false,
      listingText: '',
      listingExchange: '',
    };
  },
  created: async function () {
    this.logger = UIApplicationHelper.getLogger();
    this.logger.trace('Logger has been loaded for author disclosures view.');
    const debugQS = HTMLUtilities.GetStringQueryStringParameter('debug');
    if (debugQS !== null && debugQS === 'true') {
      this.debug = true;
      this.logger.info('DEBUG is on.');
    }
    if (this.debug) this.logger.debug('created');

    // check for k
    const kQS = HTMLUtilities.GetStringQueryStringParameter('k');
    if (kQS === null) {
      this.logger.warn(
        'query does not contain the proper query identifier (k)',
      );
    } else {
      this.k = kQS;
      this.logger.debug(`k has been specified as ${this.k}`);
    }

    // check for p
    const pQS = HTMLUtilities.GetStringQueryStringParameter('p');
    if (pQS === null) {
      this.logger.warn(
        'query does not contain the proper query identifier for /powerup/view/ (p)',
      );
    } else {
      this.p = pQS;
      this.logger.debug(`p has been specified as ${this.p}`);
      const ansCustomEmbed = JSON.parse(this.p);
      this.listingText = `${ansCustomEmbed.config.name} (${ansCustomEmbed.config.ticker})`;
      this.listingExchange = ansCustomEmbed.config.exchangeCode;
    }

    this.logger.info('Sending ready message.', {
      k: this.k,
      scollHeight: document.documentElement.scrollHeight,
    });
  },
  mounted: function() {
    if (this.debug) log('mounted', 'life');
    let height = document.getElementById('viewSection')?.getBoundingClientRect().height;
    sendReadyMessage(new URL(location.href).pathname, height);
  },
});
