<template>
  <div class="attribute-margin">
    <mds-input
      v-if="inputData"
      v-bind="inputData"
      v-model="inputData.value"
      @change="inputChanged"
      @keydown.enter.prevent=""
    ></mds-input>
    <AppInputTags
      v-if="multiInputData"
      v-bind="multiInputData"
      @change="multiInputChanged"
    />
    <mds-select
      v-if="selectData"
      v-bind="selectData"
      v-model="selectData.value"
      @change="selectChanged"
    ></mds-select>
    <mds-combo-box
      v-if="multiSelectData"
      v-bind="multiSelectData"
      v-model="multiSelectData.value"
      @input="multiSelectChanged"
      multiple
    ></mds-combo-box>
    <AppDatePicker v-if="dateData" v-bind="dateData" @selected="dateChanged" />
    <AppBooleanSelector
      v-if="boolData"
      v-bind="boolData"
      @change="boolChanged"
    />
  </div>
</template>

<script>
import AppBooleanSelector from '@/components/Taxonomy/elements/AppBooleanSelector.vue';
import AppDatePicker from '@/components/Taxonomy/elements/AppDatePicker.vue';
import AppInputTags from '@/components/Taxonomy/elements/AppInputTags.vue';
import MdsComboBox from '@mds/combo-box';
import MdsInput from '@mds/input';
import MdsSelect from '@mds/select';
import Utils from '@/js/utils.js';

export default {
  name: 'TermEditAttribute',
  components: {
    AppBooleanSelector,
    AppDatePicker,
    AppInputTags,
    MdsComboBox,
    MdsInput,
    MdsSelect,
  },
  mixins: [Utils],
  props: {
    type: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      required: true,
    },
    singleValue: {
      type: Boolean,
      required: true,
    },
    predefinedList: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Boolean, Array],
      default: '',
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      requiredError: 'This field is required',
      inputData: this.getInputData(),
      multiInputData: this.getMultiInputData(),
      selectData: this.getSelectData(),
      multiSelectData: this.getMultiSelectData(),
      dateData: this.getDateData(),
      boolData: this.getBoolData(),
    };
  },
  methods: {
    getInputData() {
      if (
        ['Free Text', 'Structured Text', 'URL'].includes(this.type) &&
        this.singleValue &&
        this.predefinedList === ''
      )
        return {
          label: this.description,
          required: this.required,
          value: this.value,
          disabled: this.readOnly,
        };
      return null;
    },
    getMultiInputData() {
      if (
        ['Free Text', 'Structured Text', 'URL'].includes(this.type) &&
        !this.singleValue &&
        this.predefinedList === ''
      )
        return {
          label: this.description,
          required: this.required,
          value: this.value === '' ? [] : this.value,
          disabled: this.readOnly,
          tooltip:
            this.name === 'synonym'
              ? 'Only create synonymous cross-references with accepted alternative names or spellings, or spelled-out versions of acronyms. Do not create terms for misspellings.'
              : '',
        };
      return null;
    },
    getSelectData() {
      if (
        ['Free Text', 'Structured Text', 'URL'].includes(this.type) &&
        this.singleValue &&
        this.predefinedList !== ''
      ) {
        let options = [{ text: '', value: '' }];
        this.predefinedList.split(',').forEach(element => {
          options.push({ text: element, value: element });
        });
        return {
          label: this.description,
          required: this.required,
          options: options,
          value: this.value,
          disabled: this.readOnly,
        };
      }
      return null;
    },
    getMultiSelectData() {
      if (
        ['Free Text', 'Structured Text', 'URL'].includes(this.type) &&
        !this.singleValue &&
        this.predefinedList !== ''
      ) {
        let options = [];
        let predefinedList = this.predefinedList.split(',');
        predefinedList.forEach(element => {
          options.push({ text: element, value: element });
        });
        let value = [];
        // remove values that are not in the predefined list
        if (this.value !== '') {
          this.value.forEach(element => {
            if (predefinedList.includes(element)) {
              value.push(element);
            }
          });
        }
        return {
          label: this.description,
          dataSet: options,
          required: this.required,
          value: value,
          disabled: this.readOnly,
        };
      }
      return null;
    },
    getDateData() {
      if (['DateTime'].includes(this.type)) {
        let result = {
          label: this.description,
          required: this.required,
          disabled: this.readOnly,
        };
        if (this.value && this.value !== '')
          result.value = new Date(this.value);
        return result;
      }
      return null;
    },
    getBoolData() {
      if (['Bool'].includes(this.type)) {
        return {
          label: this.description,
          required: this.required,
          value: this.value === '' ? null : this.value,
          disabled: this.readOnly,
        };
      }
      return null;
    },
    inputChanged() {
      this.$emit('change', this.inputData.value);
    },
    multiInputChanged(newValue) {
      this.multiInputData.value = newValue;
      this.$emit('change', newValue);
    },
    selectChanged() {
      this.$emit('change', this.selectData.value);
    },
    multiSelectChanged() {
      this.$emit('change', this.multiSelectData.value);
    },
    dateChanged(newValue) {
      this.dateData.value = newValue;
      this.$emit('change', this.formatDate(newValue));
    },
    boolChanged(newValue) {
      this.boolData.value = newValue;
      this.$emit('change', newValue);
    },
    validate() {
      if (!this.required) return '';
      const data =
        this.inputData ||
        this.multiInputData ||
        this.dateData ||
        this.selectData ||
        this.multiSelectData ||
        this.boolData;
      if (
        data.value === null ||
        data.value === undefined ||
        data.value === '' ||
        (Array.isArray(data.value) && data.value.length == 0)
      ) {
        data.error = true;
        this.$set(data, 'error', true);
        this.$set(data, 'errorText', [this.requiredError]);
        return `${this.description}, `;
      } else {
        this.$set(data, 'error', false);
        return '';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/style/global.scss';
.attribute-margin {
  margin-bottom: #{$mds-space-2-and-a-half-x};
}
</style>
