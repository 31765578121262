import get from 'lodash/get';
import set from 'lodash/set';
import Logger from './Logger';

export default {
  initializeUI(): void {
    if (!get(window, 'cosmos.logger')) {
      set(window, 'cosmos.logger', new Logger());
    }
  },
  initializeUITest(): void {
    if (!get(window, 'cosmos.logger')) {
      const logger = new Logger();
      logger.removeAllLogProviders();
      set(window, 'cosmos.logger', logger);
    }
  },
  getLogger(): Logger {
    return get(window, 'cosmos.logger');
  },
};
