import App from './App.vue';
import Vue from 'vue';
import axios from 'axios';
import store from './store';
import router from './js/router.js';
import UIApplicationHelper from './utils/UIApplicationHelper';

// Initialize logger (and potentially other global helpers)
UIApplicationHelper.initializeUI();

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.headers.common['Content-Type'] = 'application/json';

Vue.config.productionTip = false;
Vue.config.errorHandler = function (err, vm, info) {
  UIApplicationHelper.getLogger().error(err + '|' + vm + '|' + info);
};
Vue.config.warnHandler = function (msg, vm, trace) {
  UIApplicationHelper.getLogger().warn(msg + '|' + vm + '|' + trace);
};

new Vue({
  router: router,
  store,
  render: h => h(App),
}).$mount('#app');
