import { LogProvider, logTypes } from './Logger';

/* eslint-disable no-console */
export default class ConsoleLogProvider implements LogProvider {
  log(
    severity: logTypes,
    message: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    additionalInfo?: any,
    stackTrace?: string,
  ): void {
    switch (severity) {
      case logTypes.FATAL:
        console.error(
          ConsoleLogProvider.buildMessage(message, additionalInfo, stackTrace),
        );
        break;
      case logTypes.ERROR:
        console.error(
          ConsoleLogProvider.buildMessage(message, additionalInfo, stackTrace),
        );
        break;
      case logTypes.WARN:
        console.warn(
          ConsoleLogProvider.buildMessage(message, additionalInfo, stackTrace),
        );
        break;
      case logTypes.INFO:
        console.info(
          ConsoleLogProvider.buildMessage(message, additionalInfo, stackTrace),
        );
        break;
      case logTypes.DEBUG:
        console.debug(
          ConsoleLogProvider.buildMessage(message, additionalInfo, stackTrace),
        );
        break;
      case logTypes.TRACE:
      default:
        console.trace(
          ConsoleLogProvider.buildMessage(message, additionalInfo, stackTrace),
        );
        break;
    }
  }
  static buildMessage(
    message: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    additionalInfo?: any,
    stackTrace?: string,
  ): string {
    let addInfo = '';
    if (additionalInfo) {
      addInfo = ` : Additional Info: ${JSON.stringify(additionalInfo)}`;
    }
    let stack = '';
    if (stackTrace) {
      stack = ` : StackTrace: ${stackTrace}`;
    }
    return `${message}${addInfo}${stack}`;
  }
}
