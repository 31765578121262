<template>
  <mds-section border="none" id="viewSection">
    <ul id="template__list">
      <li :key="index" v-for="(value, key, index) in taxonomyAttributes">
        <span
          >{{ key }}:
          <span style="color: grey">{{ value.join(', ') }}</span></span
        >
      </li>
    </ul>
    <ul id="template__list">
      <li :key="index" v-for="(value, key, index) in taxonomyRelationships">
        <span
          >{{ key }}:
          <span style="color: grey">{{ value.join(', ') }}</span></span
        >
      </li>
    </ul>
  </mds-section>
</template>

<script>
import MdsSection from '@mds/section';
import { log, sendReadyMessage, sendCancelMessage } from '@/js/powerup.js';
import Constants from '@/js/constants.js';

export default {
  name: 'PowerupViewComponent',
  components: {
    MdsSection,
  },
  mixins: [Constants],
  /**
   * 1. See if debug is on
   * 2. check to make sure query contains both k and p.
   * 3. normalizeData
   */
  created: async function () {
    if (this.$route.query.debug !== undefined) {
      if (this.$route.query.debug === 'true') {
        log('created', 'life');
        this.debug = true;
        log('DEBUG is on.', 'info');
        log('this.$route.query: ', 'dir', this.$route.query);
      }
    }

    // check for p and k
    if (
      this.$route.query.p === undefined ||
      this.$route.query.k === undefined
    ) {
      if (this.debug)
        log('query does not contain the proper query identifiers', 'error');
      this.cancel();
    } else {
      this.p = this.$route.query.p;
      this.k = this.$route.query.k;
      // normalize data
      await this.normalizeData();
    }
  },
  /**
   * Called after the page has been mounted.
   * There is a style conflict between prettier and another linter that will not allow compilation if not ignored.
   */
  // prettier-ignore
  /**
   * Send message to the parent that this page is ready and include height.
   */
  mounted: function() {
    if (this.debug) log('mounted', 'life');
    let height = document.getElementById('viewSection')?.getBoundingClientRect().height;
    sendReadyMessage(new URL(location.href).pathname, height);
  },
  data() {
    return {
      debug: false,
      /** query identifier in the URL (...view?p=...) This is the encoded taxonomy object. */
      p: Object,
      /** query identifier in the URL (...view?k=...) This is the ARC identifier ID. */
      k: Number,
      /** the object that is created after the url has been parsed */
      taxonomyAttributes: {},
      taxonomyRelationships: {},
    };
  },
  methods: {
    normalizeData() {
      try {
        // this.$route.query.p has already been checked in this.checkQuery
        let query = JSON.parse(this.$route.query.p);

        let queryValues = [];
        if (query.hasOwnProperty('config'))
          queryValues = Object.values(query.config);

        let attributes = queryValues.filter(
          item => item['taxonomy_class'] === 'attribute',
        );

        let relationships = queryValues.filter(
          item => item['taxonomy_class'] !== 'attribute',
        );

        attributes.forEach(element => {
          if (
            !this.taxonomyAttributes.hasOwnProperty(
              this.TAXONOMY_ATTRIBUTE_LABEL[element['taxonomy_id']],
            )
          ) {
            this.taxonomyAttributes[
              this.TAXONOMY_ATTRIBUTE_LABEL[element['taxonomy_id']]
            ] = [];
            this.taxonomyAttributes[
              this.TAXONOMY_ATTRIBUTE_LABEL[element['taxonomy_id']]
            ].push(element['taxonomy_name']);
          } else {
            this.taxonomyAttributes[
              this.TAXONOMY_ATTRIBUTE_LABEL[element['taxonomy_id']]
            ].push(element['taxonomy_name']);
          }
        });

        relationships.forEach(element => {
          if (
            !this.taxonomyRelationships.hasOwnProperty(
              element['taxonomy_class'],
            )
          ) {
            this.taxonomyRelationships[element['taxonomy_class']] = [];
            this.taxonomyRelationships[element['taxonomy_class']].push(
              element['taxonomy_name'],
            );
          } else {
            this.taxonomyRelationships[element['taxonomy_class']].push(
              element['taxonomy_name'],
            );
          }
        });
        if (this.debug) log('data has been normalized', 'info');
      } catch (error) {
        // this will throw an error if the url is not parsed correctly
        if (this.debug) log('The URL was not parsed properly', 'error', error);
        this.cancel();
      }
    },
    cancel() {
      sendCancelMessage(new URL(location.href).pathname);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/style/global.scss';
li::first-letter {
  text-transform: capitalize;
}
ul {
  position: relative;
  left: -22px;
  margin: 0;
}
#li span:first-child {
  position: relative;
  left: -3px;
}
</style>
