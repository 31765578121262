export interface ValueObject {
  value: string;
}
export const SortByValueAsc = (a: ValueObject, b: ValueObject): number => {
  const nameA = a.value.toUpperCase(); // ignore upper and lowercase
  const nameB = b.value.toUpperCase(); // ignore upper and lowercase
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }

  // names must be equal
  return 0;
};
export const BytesToMegaBytes = (bytes: number): number => bytes / 1024 ** 2;
