import axios, {
  AxiosResponse,
  AxiosRequestConfig,
  AxiosRequestHeaders,
} from 'axios';
import { QueryResponseObject } from './api-request';
import Vue from 'vue';

const client = axios.create({
  baseURL: process.env.VUE_APP_URL,
});

const request = async (
  options: AxiosRequestConfig,
): Promise<QueryResponseObject> => {
  const onSuccess = (
    response: AxiosResponse<QueryResponseObject>,
  ): Promise<QueryResponseObject> => {
    console.debug('Request successfull', response);
    return Promise.resolve(response.data);
  };
  const onError = (error: any) => {
    if (axios.isAxiosError(error)) {
      console.error('Request Failed', error);
      console.error('Request Failed:', error.config);
      if (error.response) {
        console.error('Status:', error.response.status);
        console.error('Data:', error.response.data);
        console.error('Headers:', error.response.headers);
      } else {
        console.error('Error Mesage:', error.message);
      }
      if (error.response && error.response.data) {
        return Promise.reject(error.response.data);
      }
      return Promise.reject(error.response || error.message);
    } else {
      console.error('unexpected error: ', error);
      return Promise.reject('An unexpected axios error occurred');
    }
  };

  let headers: AxiosRequestHeaders = {
    'Content-Type': 'application/json'  
  }

  if (process.env.VUE_APP_REQUIRES_AUTH !== 'false')
  {
    headers['x-api-key'] = process.env.API_KEY || '';
    headers['Authorization'] = `Bearer ${await Vue.prototype.$auth.getAccessToken()}`;
  }
  
  const ops = { ...options };
  if (ops.headers) {
    ops.headers = { ...options.headers, ...headers };
  } else {
    ops.headers = { ...headers };
  }

  try {
    const response = await client(ops);
    return onSuccess(response);
  } catch (error) {
    return onError(error);
  }
};
export default request;
