<template>
  <g :transform="rotate">
    <rect
      v-bind:class="buttonClass"
      :width="nodeWidth"
      :height="nodeHeight"
      cursor="pointer"
      rx="20"
    ></rect>
    <text
      v-bind:class="buttonText"
      text-anchor="middle"
      dominant-baseline="middle"
      cursor="pointer"
      :y="nodeHeight / 2"
      :x="nodeWidth / 2"
      >{{ node.data.name.split(';')[0] }}</text
    >
  </g>
</template>

<script>
import Utils from '@/js/utils.js';

export default {
  name: 'PowerupHierarchyNode',
  props: {
    node: {
      type: Object,
    },
    nodeWidth: {
      type: Number,
    },
    nodeHeight: {
      type: Number,
    },
  },
  mixins: [Utils],
  data() {
    return {
      isClicked: false,
      hasChildren: true,
      iconSearch: 'iconSearch',
      facetColorMap: {
        first: 'first-facet-text',
        second: 'second-facet-text',
        third: 'third-facet-text',
        fourth: 'fourth-facet-text',
        fifth: 'fifth-facet-text',
        sixth: 'sixth-facet-text',
      },
    };
  },
  computed: {
    rotate() {
      return `translate(${this.node.x}, ${this.node.y})`;
    },
    buttonClass() {
      if (this.node.data.clicked) {
        return 'clicked-button';
      } else if (this.node.data.isEndNode) {
        return 'no-children-button';
      } else {
        return 'not-clicked-button';
      }
    },
    buttonText() {
      let currentClass = 'larger-font';

      if (this.node.data.hasOwnProperty('facet'))
        currentClass =
          currentClass + ' ' + this.facetColorMap[this.node.data.facet];
      else {
        if (this.node.data.isEndNode)
          currentClass = currentClass + ' no-children-text';
      }

      return currentClass;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/style/global.scss';
@import '@mds/constants';

.larger-font {
  font-size: larger;
  font-weight: 500;
}
.clicked-button {
  fill: $mds-visualization-color-valuation-fair;
  stroke: $mds-color-neutral-37;
}
.not-clicked-button {
  fill: $mds-color-white;
  stroke: $mds-color-black;
}
.no-children-button {
  fill: $mds-color-white;
  stroke: $mds-color-neutral-67;
}
.first-facet-text {
  fill: $mds-text-color-error;
}
.second-facet-text {
  fill: $mds-color-blue-41;
}
.third-facet-text {
  fill: $mds-color-dark-green-25;
}
.fourth-facet-text {
  fill: $mds-color-dark-yellow-47;
}
.fifth-facet-text {
  fill: $mds-color-violet-46;
}
.sixth-facet-text {
  fill: $mds-color-yellow-48;
}
.no-children-text {
  fill: $mds-color-neutral-37;
}
</style>
