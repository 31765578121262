// http://localhost:8080/powerup/edit?debug=true&p=%7B%22id%22%3A%22concept-1%22,%22url%22%3A%22%2F%22,%22config%22%3A%7B%22taxonomy_item_0%22%3A%7B%22taxonomy_name%22%3A%22Investments%22,%22taxonomy_id%22%3A%22concept-1%22,%22taxonomy_class%22%3A%22concept%22%7D,%22taxonomy_item_2%22%3A%7B%22taxonomy_name%22%3A%22Brexit%22,%22taxonomy_id%22%3A%22event-1%22,%22taxonomy_class%22%3A%22event%22%7D%7D%7D&k=kjjk

/**
 * A mapping of class descriptions.
 */
export const classDescriptions = new Map([
  [
    'concept',
    'Add one or two Concepts that describe the topics the article addresses. Be as specific as possible in your selection. For example, choose 401(k), not Retirement; Bitcoin, not Cryptocurrency; Carbon, not Climate.',
  ],
  [
    'company',
    "Add the Company names discussed in the article. Avoid adding companies that are only briefly mentioned. Editor's tip: Copy and paste company names from the article for an easier search.",
  ],
  [
    'event',
    'Add significant Events discussed in the article. Some examples include: Brexit; 2022 Russian Invasion of Ukraine.',
  ],
  [
    'fund',
    "Add the funds discussed in the article. Avoid adding funds that are only briefly mentioned. Editor's tip: Copy and paste Fund names from the article for an easier search.",
  ],
  [
    'fundManager',
    "Add the name of the Fund Manager discussed in the article. Editor's tip: Copy and paste the Fund Manager's name from the article for an easier search.",
  ],
  [
    'branding',
    "Add the name of the Fund Family discussed in the article. Copy and paste the Fund Family's name from the article for an easier search.",
  ],
]);

/**
 * These classes are generally the only ones used in the taxonomy powerup.
 * country, place, and exchange are not included, but can be added back at any time.
 * */
export const classes = {
  public: ['concept', 'company', 'event', 'fund', 'branding', 'fundManager'],
  private: ['people', 'country', 'place', 'exchange', 'organization'],
};

/**
 * Posts the 'ready' message to the parent along with the height of the div.
 * @param {string} source The name of the class that is calling this method.
 * @param {number | undefined} height This is the height of the div.
 */
export const sendReadyMessage = function(source, height) {
  const debug = parseQueryString('debug') ?? false;
  if (height === undefined || height === null) height = 90;
  if (height < 90) height = 90;
  window.parent.postMessage(
    JSON.stringify({
      "source": 'custom_embed',
      "action": 'ready',
      "data": { "height": height },
      key: parseQueryString('k')
    }),
    '*',
  );
  if (debug) log(`'ready' has been sent from ${source} with a height of ${height}`, 'parent');
};

/**
 * Sends the cancel message to the parent. This is what is called when the PowerUp modal needs to be closed.
 * @param {string} source The name of the class that is calling this method.
 */
export const sendCancelMessage = function(source) {
  const debug = parseQueryString('debug') ?? false;
  window.parent.postMessage(
    JSON.stringify({
      "source": 'custom_embed',
      "action": 'cancel',
      "data": null,
      key: parseQueryString('k')
    }),
    '*',
  );
  if (debug) log(`'cancel' has been sent from ${source}.`, 'parent');
};

/**
 * Sends the data message to the parent along with the actual data.
 * @param {string} source The name of the class that is calling this method.
 * @param {object} data This is the data that is being provided by the PowerUp such as Taxonomy data.
 */
export const sendDataMessage = function(source, data) {
  const debug = parseQueryString('debug') ?? false;
  window.parent.postMessage(
    JSON.stringify({
      "source": 'custom_embed',
      "action": 'data',
      data,
      key: parseQueryString('k')
    }),
    '*',
  );
  if (debug) log(`'data' has been sent from ${source}.`, 'parent');
};

const parseQueryString = function(key) {
  const url = new URL(location.href);
  return url.searchParams.get(key);
};

/** If PowerupSearchComponent is being used to edit, then the taxonomy object will be attached as the url query. */
/**
 * Parses the URL to get a taxonomy object.
 * @param {object} query The URL query.
 * @param {bool} debug If debug is on.
 * @returns Either an empty or parsed taxonomy object.
 */
export const parseURLQuery = function (query, debug) {
  if (query === null || query === undefined) return {};
  if (query.p === undefined || query.p === null) return {};
  try {
    let p = JSON.parse(query.p);
    if (p.config !== undefined && p.config != null) {
      if (debug) log('p.config: ', 'dir', p.config);
      return p.config;
    }
  } catch (error) {
    // this will throw an error if the url is not parsed correctly
    log(
      'There was an error while trying to parse the URL for /powerup/edit',
      'error',
      error,
    );
    // return blank object on error
    return {};
  }
};

/**
 * Logging for debugging.
 * @param {string} message The message to output to the console.
 * @param {string} severity info | warn | error | dir | life | parent
 * @param {object} object An optional object to be logged.
 */
export const log = function (message, severity, object = null) {
  if (message === undefined) return;
  switch (severity) {
    case 'info':
      console.info(message);
      break;
    case 'warn':
      console.warn(message);
      console.warn('Are you connected to the VPN?');
      break;
    case 'error':
      console.error(message);
      console.warn('Are you connected to the VPN?');
      if (object !== undefined && object !== null) console.dir(object);
      break;
    case 'dir':
      console.info(message);
      console.dir(object);
      break;
    case 'life':
      console.log(
        `%c${message}`,
        'color:white; background-color:blue; font-size:20px;',
      );
      break;
    case 'parent':
      console.log(`%c${message}`,'color:green; font-size:14px;',);
      break;
    default:
      console.log(message);
      break;
  }
};
