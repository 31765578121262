
import Vue from 'vue';
import axios from 'axios';
import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid';
import MdsSection from '@mds/section';
import MdsLoader from '@mds/loader';
import MdsForm from '@mds/form';
import MdsSearchField from '@mds/search-field';
import MdsComboBox from '@mds/combo-box';
import { MdsButton } from '@mds/button';
import MdsPagination from '@mds/pagination'
import UIApplicationHelper from 'Utils/UIApplicationHelper';
import Logger from 'Utils/Logger';
import {
  EXCHANGE_CODE_OPTIONS,
  ExchangeCodeOption,
  INVESTMENT_TYPES,
  INVESTMENT_TYPE_LISTING,
  INVESTMENT_TYPE_SHARECLASS,
} from 'Constants/index';
import requestData, {
  instanceOfQueryResponseObject,
  PerformanceObject,
  QueryResponseObject,
  ShareclassObject,
} from 'Utils/api-request';
import {
  MdsTable,
  MdsThead,
  MdsTh,
  MdsTbody,
  MdsTr,
  MdsTd,
} from '@mds/data-table';
import { SortByValueAsc } from 'Utils/ObjectUtilities';
import { log, sendDataMessage } from '@/js/powerup';

interface PaginationOptions {
  page: number;
  pageSize: number;
  firstItem: number;
  lastItem: number;
}

export default Vue.extend({
  name: 'PowerupSearchEditComponent',
  components: {
    MdsLayoutGrid,
    MdsRow,
    MdsCol,
    MdsSection,
    MdsLoader,
    MdsForm,
    MdsSearchField,
    MdsComboBox,
    MdsButton,
    MdsTable,
    MdsThead,
    MdsTh,
    MdsTbody,
    MdsTr,
    MdsTd,
    MdsPagination,
  },
  created: async function () {
    if (this.$route.query.debug !== undefined) {
      if (this.$route.query.debug === 'true') {
        this.debug = true;
        log('DEBUG is on.', 'info', null);
      }
    }
    if (this.debug) log('created', 'life');
    if (this.debug) log(`route: ${this.$route.path}`, 'debug');
    if (this.$route.path === '/powerup/edit') {
      if (this.debug) log('this.edit == true', 'info');
      this.edit = true;
    }

    // check for k
    if (this.$route.query.k === undefined) {
      if (this.debug)
        log('query does not contain the proper query identifier (k)', 'warn');
    } else {
      this.k = this.$route.query.k as string;
    }

    // if page is edit, check for p
    if (this.edit && this.$route.query.p === undefined) {
      if (this.debug)
        log(
          'query does not contain the proper query identifier for /powerup/edit/ (p)',
          'warn',
        );
    } else {
      this.p = this.$route.query.p as string;
    }
  },
  beforeMount: async function () {
    this.logger = UIApplicationHelper.getLogger();
    this.logger.trace(
      'Logger has been loaded for investment search edit component.',
    );

    if (this.p) {
      const customEmbed = JSON.parse(this.p);
      if (customEmbed) {
        this.selectedInvestmentTypes = [];
        this.selectedInvestmentTypes.push(customEmbed.config.investment_type);
        if (customEmbed.config.investment_type === INVESTMENT_TYPE_LISTING) {
          this.results.push({
            id: customEmbed.id,
            standardName: customEmbed.config.name,
            symbol: customEmbed.config.ticker,
            exchangeCode: customEmbed.config.exchange,
            datalakeUpdateDateUtc: '',
            updateDateUtc: '',
          } as PerformanceObject);
        } else if (
          customEmbed.config.investment_type === INVESTMENT_TYPE_SHARECLASS
        ) {
          this.results.push({
            id: customEmbed.id,
            standardName: customEmbed.config.name,
            symbol: customEmbed.config.ticker,
            countryCode: customEmbed.config.country,
            datalakeUpdateDateUtc: '',
            updateDateUtc: '',
          } as ShareclassObject);
        }
        this.error = '';
        this.totalCount = this.results.length;
      }
    }
    this.isLoading = false;
  },
  /**
   * Called after the page has been mounted.
   * document.documentElement.scrollHeight will only be accurate after the page has been mounted.
   * There is a style conflict between prettier and another linter that will not allow compilation if not ignored.
   * 1. Send a message to the parent that this page is ready.
   */
  // prettier-ignore
  mounted: async function() {
    if (this.debug) log('mounted', 'life');
  },
  data() {
    return {
      hover: false,
      logger: {} as Logger,
      isLoading: true,
      error: '',
      p: 'p',
      k: 'k',
      debug: false,
      edit: false,
      selectedExchangeCodes: [] as Array<string>,
      investmentTypeOptions: INVESTMENT_TYPES,
      selectedInvestmentTypes: [] as Array<string>,
      investmentSearchQuery: '',
      resultsLoading: false,
      pageSize: 10,
      currentPage: 1,
      totalCount: 0,
      finalObject: { id: '', url: '', config: {} },
      results: [] as Array<PerformanceObject | ShareclassObject>,
      investmentTypeListing: INVESTMENT_TYPE_LISTING,
      exchangeCodeOptions: EXCHANGE_CODE_OPTIONS.sort(
        SortByValueAsc,
      ) as Array<ExchangeCodeOption>,
    };
  },
  computed: {
    headers() {
      const headers = [
        {
          text: 'Id',
          fieldName: 'id',
        },
        {
          text: 'Name',
          fieldName: 'standardName',
        },
      ];
      if (this.selectedInvestmentType !== INVESTMENT_TYPE_LISTING) {
        headers.push({
          text: 'Country',
          fieldName: 'countryCode',
        });
      } else {
        headers.push({
          text: 'Exchange Code',
          fieldName: 'exchangeCode',
        });
      }

      if (
        this.selectedInvestmentType === INVESTMENT_TYPE_LISTING ||
        this.selectedInvestmentType === INVESTMENT_TYPE_SHARECLASS
      ) {
        headers.push({
          text: 'Ticker',
          fieldName: 'symbol',
        });
      }
      return headers;
    },
    selectedInvestmentType(): string {
      if (this.selectedInvestmentTypes.length === 1) {
        return this.selectedInvestmentTypes[0];
      } else if (this.selectedInvestmentTypes.length === 0) {
        return '';
      }
      throw 'Invalid investment type: Multiple investment types selected.';
    },
  },
  methods: {
    // conditional watcher for investment type
    // this is set in created after parsing the p parameter
    watchSelectedInvestmentType(newType: string, oldType: string) {
      this.logger.info(
        `Investment types changed from ${oldType} to ${newType}`,
      );
      this.resetSearchResults();
      this.investmentSearchQuery = '';
      this.selectedExchangeCodes = [];
    },
    resetSearchResults() {
      this.currentPage = 1;
      this.totalCount = 0;
      this.results = [];
      this.error = '';
    },
    onSubmitSearch() {
      this.resetSearchResults();
      this.onSearch();
    },
    onSearch() {
      const vm = this;
      if (vm.resultsLoading === false) {
        vm.resultsLoading = true;
        requestData(vm.selectedInvestmentType, {
          q: encodeURIComponent(vm.investmentSearchQuery),
          take: vm.pageSize,
          ec: vm.selectedExchangeCodes,
          skip: (vm.currentPage - 1) * vm.pageSize,
        })
          .then((data: QueryResponseObject) => {
            vm.logger.info('Got data', data);
            const listingResponse = data as QueryResponseObject<
              PerformanceObject | ShareclassObject
            >;
            if (listingResponse.errors === null) {
              vm.error = '';
              vm.totalCount = listingResponse.totalCount;
              vm.results = listingResponse.data;
            } else {
              vm.error = [...listingResponse.errors].join(',');
              vm.totalCount = 0;
              vm.results = [];
            }
          })
          .catch((error: unknown) => {
            vm.totalCount = 0;
            vm.results = [];

            if (instanceOfQueryResponseObject(error)) {
              if (error.errors !== null) {
                vm.error = [...error.errors].join(',');
              }
            } else if (axios.isAxiosError(error)) {
              vm.logger.error(`Request Failed`, error);
              if (error.response) {
                vm.logger.error(`Status: ${error.response.status}`);
                vm.logger.error(`Data: ${error.response.data}`);
                vm.logger.error(`Headers: ${error.response.headers}`);
              } else {
                vm.logger.error(`Error Message: ${error.message}`);
                vm.error = `There was an unexpected error querying listing data: ${error.message}.`;
              }
            } else {
              vm.logger.error('Unexpected error', error);
              if (error instanceof Error) {
                vm.error = `There was an unexpected error querying listing data: ${error.message}.`;
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
              } else if ((error as any).message !== undefined) {
                vm.error = `There was an unexpected error querying listing data: ${
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  (error as any).message
                }.`;
              } else {
                vm.error = `There was an unexpected error querying listing data.`;
              }
            }
          })
          .finally(function () {
            vm.resultsLoading = false;
          });
      }
    },
    handlePageChange(options: PaginationOptions) {
      this.pageSize = options.pageSize;
      this.currentPage = options.page;
      this.onSearch();
    },
    addInvestment(listing: PerformanceObject) {
      this.logger.info('Listing clicked', listing);
      this.finalObject.id = listing.id;
      this.finalObject.url = '/';
      this.finalObject.config = {
        investment_type: INVESTMENT_TYPE_LISTING,
        name: listing.standardName,
        ticker: listing.symbol,
        exchangeCode: listing.exchangeCode,
      };
      this.logger.info(
        'Sending message after adding listing.',
        this.finalObject,
      );
      sendDataMessage(new URL(location.href).pathname, this.finalObject);
    },
  },
});
