<template>
  <div class="body-nav">
    <div class="vertical-nav">
      <PowerupHierarchyNav
        :optionsList="optionsList"
        :nextTerms="nextTerms"
        :relatedTermsCache="relatedTermsCache"
        @change="selectConcept"
      />
    </div>
    <div class="content-nav">
      <div class="content-spacing">
        <div id="overlay">
          <mds-loader id="loader"></mds-loader>
        </div>
        <PowerupHierarchyGraph
          v-for="(options, index) in treeData"
          :key="index"
          :treeData="treeData[index]"
          @change="selectConcept"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PowerupHierarchyGraph from './PowerupHierarchyGraph.vue';
import PowerupHierarchyNav from './PowerupHierarchyNav.vue';
import MdsLoader from '@mds/loader';
import Request from '@/js/request.js';

export default {
  name: 'PowerupHierarchy',
  components: {
    PowerupHierarchyGraph,
    PowerupHierarchyNav,
    MdsLoader,
  },
  data() {
    return {
      optionsList: [],
      treeData: [],
      levelOne: [],
      results: [],
      relatedTermsCache: {},
      currentTerms: [],
      isSearching: false,
      nextTerms: [],
      cache: [],
      activeInMount: false,
    };
  },
  async mounted() {
    await this.getFirstLevel();
    if (this.activeConcept != undefined) {
      this.activeInMount = true;
      let parameters = { id: this.activeConcept, type: 'path' };
      await this.hierarchy(parameters);
      let index = this.results.findIndex(x => x.id === this.activeConcept);
      let currConcept = this.results[index];
      this.selectConcept({
        idx: index,
        value: currConcept.id,
        name: currConcept.name,
        isEnd: currConcept.isEndNode,
        facet: currConcept.facet,
      });
    }
  },
  methods: {
    async getFirstLevel() {
      this.isSearching = true;
      const response_body = await Request.get('/hierarchy');
      this.levelOne = response_body;
      let conceptList = [];
      for (let i in this.levelOne) {
        conceptList.push({
          text: this.levelOne[i].name,
          value: this.levelOne[i].id,
          isEndNode: this.levelOne[i].isEndNode,
          facet: this.levelOne[i].facet,
        });
      }
      this.optionsList.push(conceptList);
      for (let i in this.levelOne) {
        conceptList = [];
        conceptList.push({
          name: this.levelOne[i].name,
          parent: '',
          term: this.levelOne[i].id,
          level: 0,
          clicked: false,
          isEndNode: this.levelOne[i].isEndNode,
          facet: this.levelOne[i].facet,
        });
        this.treeData.push(conceptList);
      }
      this.isSearching = false;
    },
    async groupByFacet(list) {
      let sortedList = list.reduce(function (rv, x) {
        (rv[x['facet']] = rv[x['facet']] || []).push(x);
        return rv;
      }, {});
      let groupedByFacet = [];
      let facetedKeys = [
        'first',
        'second',
        'third',
        'fourth',
        'fifth',
        'sixth',
      ];
      let facetKeys = Object.keys(sortedList);
      let unfacetedTerms;
      for (let i = 0; i < facetedKeys.length; i++) {
        if (facetKeys.includes(facetedKeys[i])) {
          let terms = sortedList[facetedKeys[i]];
          terms.sort((a, b) => a.name.localeCompare(b.name));
          for (const term of terms) {
            if (term.facet != null) {
              groupedByFacet.push(term);
            }
          }
        }
      }
      if (facetKeys.includes('null')) {
        unfacetedTerms = sortedList[null];
        unfacetedTerms.sort((a, b) => a.name.localeCompare(b.name));
        for (const unfacetedTerm of unfacetedTerms) {
          groupedByFacet.push(unfacetedTerm);
        }
      }
      return groupedByFacet;
    },
    async hierarchy(parameters) {
      const parameters_ = {};
      for (let key in parameters) {
        if (parameters[key] && parameters[key] !== '')
          parameters_[key] = parameters[key];
      }
      if (this.isSearching) return;
      this.isSearching = true;
      const response = await Request.get(
        '/hierarchy',
        parameters,
        this.ignoreCache,
      );
      if (parameters['type'] === 'path') {
        this.results = response;
        response.forEach(element => {
          this.$set(
            this.relatedTermsCache,
            element['id'],
            element['relatedTerms'],
          );
        });
      } else {
        this.results = response['narrowerTerms'];
        this.$set(
          this.relatedTermsCache,
          parameters['id'],
          response['relatedTerms'],
        );
      }
      this.isSearching = false;
    },
    async selectConcept(inputObject) {
      if (inputObject.idx == 0) {
        let conceptList = [];
        conceptList.push({
          name: inputObject.name,
          parent: '',
          term: inputObject.value,
          level: 0,
          clicked: true,
          isEndNode: this.levelOne.find(item => item.name === inputObject.name)
            .isEndNode,
          facet: this.levelOne.find(item => item.name === inputObject.name)
            .facet,
        });
        this.treeData.push(conceptList);
      } else if (this.activeInMount == true) {
        let conceptList = [];
        conceptList.push({
          name: this.results[0].name,
          parent: '',
          term: this.results[0].id,
          level: 0,
          clicked: true,
          isEndNode: this.results[0].isEndNode,
        });
        this.treeData.push(conceptList);
      }
      let newList = this.treeData[this.treeData.length - 1];
      let currTerm = this.cache.find(
        item => item.broadTerm === inputObject.name,
      );
      if (this.currentTerms[inputObject.idx] != inputObject.value) {
        if (inputObject.idx == 0) {
          this.treeData.splice(0, this.treeData.length);
        }
        if (currTerm) {
          this.optionsList.splice(inputObject.idx + 1, this.optionsList.length);
          let filtered = newList.filter(
            item => item.level < inputObject.idx + 1,
          );
          newList = filtered;
          for (let i in newList) {
            if (
              newList[i].level == inputObject.idx &&
              newList[i].term != inputObject.value
            ) {
              newList[i].clicked = false;
            } else if (
              newList[i].level == inputObject.idx &&
              newList[i].term == inputObject.value
            ) {
              newList[i].clicked = true;
            }
          }
          this.optionsList.push(currTerm.navTerms);
          for (let i in currTerm.treeTerms) {
            newList.push(currTerm.treeTerms[i]);
          }
          this.treeData.splice(0, this.treeData.length);
          this.treeData.push(newList);
          this.currentTerms[inputObject.idx] = inputObject.value;
          this.nextTerms.splice(inputObject.idx, this.nextTerms.length);
          this.nextTerms.push(inputObject.value);
        } else {
          if (this.activeInMount == false) {
            this.optionsList.splice(
              inputObject.idx + 1,
              this.optionsList.length,
            );
            let filtered = newList.filter(
              item => item.level < inputObject.idx + 1,
            );
            newList = filtered;
            for (let i in newList) {
              if (
                newList[i].level == inputObject.idx &&
                newList[i].term != inputObject.value
              ) {
                newList[i].clicked = false;
              } else if (
                newList[i].level == inputObject.idx &&
                newList[i].term == inputObject.value
              ) {
                newList[i].clicked = true;
              }
            }
            let parameters = { id: inputObject.value };
            await this.hierarchy(parameters);
            this.results = await this.groupByFacet(this.results);
            let conceptList = [];
            for (let i in this.results) {
              conceptList.push({
                text: `${this.results[i].name};${inputObject.name}`,
                value: this.results[i].id,
                isEndNode: this.results[i].isEndNode,
                facet: this.results[i].facet,
              });
            }
            this.optionsList.push(conceptList);
            for (let i in this.results) {
              newList.push({
                name: `${this.results[i].name};${inputObject.name}`,
                parent: inputObject.name,
                term: this.results[i].id,
                level: inputObject.idx + 1,
                clicked: false,
                isEndNode: this.results[i].isEndNode,
                facet: this.results[i].facet,
              });
            }
            let treeTerms = newList.filter(
              item => item.level > inputObject.idx,
            );
            if (inputObject.idx > 0) {
              this.treeData.splice(0, this.treeData.length);
            }
            this.treeData.push(newList);
            this.cache.push({
              broadTerm: inputObject.name,
              navTerms: conceptList,
              treeTerms: treeTerms,
              level: inputObject.idx + 1,
            });
            this.currentTerms[inputObject.idx] = inputObject.value;
            this.nextTerms.splice(inputObject.idx, this.nextTerms.length);
            this.nextTerms.push(inputObject.value);
          } else {
            let tempTreeData = newList;
            let parentName = '';
            for (let i in this.results) {
              if (i == 0) {
                parentName = this.results[i].name;
              } else {
                parentName = this.results[i].name + ';' + parentName;
              }
              await this.calculateLists(
                i,
                this.results[i].name,
                this.results[i].id,
                this.results[i].narrowerTerms,
                parentName,
                tempTreeData,
              );
            }
            this.treeData.push(tempTreeData);
          }
          this.activeInMount = false;
        }
      } else {
        this.optionsList.splice(inputObject.idx + 1, this.optionsList.length);
        for (let i in newList) {
          if (newList[i].level == inputObject.idx) {
            newList[i].clicked = false;
          }
        }
        if (inputObject.idx == 0) {
          this.treeData.splice(0, this.treeData.length);
          for (let i in this.levelOne) {
            let conceptList = [];
            conceptList.push({
              name: this.levelOne[i].name,
              parent: '',
              term: this.levelOne[i].id,
              level: 0,
              clicked: false,
              isEndNode: this.levelOne[i].isEndNode,
              facet: this.levelOne[i].facet,
            });
            this.treeData.push(conceptList);
          }
        } else {
          let filtered = newList.filter(
            item => item.level < inputObject.idx + 1,
          );
          newList = filtered;
          this.treeData.splice(0, this.treeData.length);
          this.treeData.push(newList);
        }
        for (let i in this.cache) {
          if (this.cache[i].level >= inputObject.idx) {
            let currTerm = this.cache[i].treeTerms;
            for (let j in currTerm) {
              currTerm[j].clicked = false;
            }
          }
        }
        for (let i in this.currentTerms) {
          if (inputObject.idx <= i) {
            this.currentTerms[i] = '';
          }
        }
        this.nextTerms.splice(inputObject.idx, this.nextTerms.length);
        this.nextTerms.push('');
      }
      if (this.activeInMount == true) {
        let tempTreeData = newList;
        let parentName = '';
        for (let i in this.results) {
          if (i == 0) {
            parentName = this.results[i].name;
          } else {
            parentName = this.results[i].name + ';' + parentName;
          }
          await this.calculateLists(
            i,
            this.results[i].name,
            this.results[i].id,
            this.results[i].narrowerTerms,
            parentName,
            tempTreeData,
          );
        }
        this.treeData.push(tempTreeData);
        this.activeInMount = false;
      }
    },
    async calculateLists(
      index,
      inputName,
      inputValue,
      inputList,
      parentName,
      tempTreeData,
    ) {
      inputList = await this.groupByFacet(inputList);
      let conceptList = [];
      for (let i in inputList) {
        conceptList.push({
          text: `${inputList[i].name};${inputName}`,
          value: inputList[i].id,
          isEndNode: inputList[i].isEndNode,
          facet: inputList[i].facet,
        });
      }
      if (conceptList.length != 0) {
        this.optionsList.push(conceptList);
      }
      for (let i in inputList) {
        let isClicked = false;
        let nextLevel =
          parseInt(index) + 1 < this.results.length
            ? this.results[parseInt(index) + 1].name
            : '';
        if (inputList[i].name == nextLevel) {
          isClicked = true;
        }
        tempTreeData.push({
          name: `${inputList[i].name};${parentName}`,
          parent: parentName,
          term: inputList[i].id,
          level: parseInt(index) + 1,
          clicked: isClicked,
          isEndNode: inputList[i].isEndNode,
          facet: inputList[i].facet,
        });
        isClicked = false;
      }
      let treeTerms = tempTreeData.filter(item => item.level > index);
      if (index > 0) {
        this.treeData.splice(0, this.treeData.length);
      }
      this.cache.push({
        broadTerm: inputName,
        navTerms: conceptList,
        treeTerms: treeTerms,
        level: index + 1,
      });
      this.currentTerms[index] = inputValue;
      this.nextTerms.push(inputValue);
    },
  },
  computed: {
    activeConcept() {
      return this.$route.params.conceptId;
    },
  },
  watch: {
    isSearching(newValue) {
      if (newValue) {
        document.getElementById('overlay').style.display = 'block';
      } else {
        document.getElementById('overlay').style.display = 'none';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.body-nav {
  display: grid;
  grid-template-columns: 230px auto;
}
.vertical-nav {
  background-color: #f2f2f2;
  grid-column: 1 / span 1;
  height: calc(100vh - 20px);
  overflow-y: auto;
  padding: 16px;
  position: fixed;
  width: 200px;
}
.content-nav {
  grid-column: 2 / span 1;
  min-width: 100%;
  width: 100%;
}
.content-spacing {
  padding: 16px 16px;
}

#overlay {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 2;
}

#loader {
  top: 200px;
}
</style>
