import { ValueObject } from '@/utils/ObjectUtilities';

export interface ExchangeCodeOption extends ValueObject {
  key: string;
  text: string;
}
export const EXCHANGE_CODE_OPTIONS: Array<ExchangeCodeOption> = [
  { key: 'NEOE', text: 'NEOE', value: 'NEOE' },
  { key: 'KUW', text: 'KUW', value: 'KUW' },
  { key: 'PHS', text: 'PHS', value: 'PHS' },
  { key: 'OVSE', text: 'OVSE', value: 'OVSE' },
  { key: 'OSTO', text: 'OSTO', value: 'OSTO' },
  { key: 'NGM', text: 'NGM', value: 'NGM' },
  { key: 'SHSE', text: 'SHSE', value: 'SHSE' },
  { key: 'LUS', text: 'LUS', value: 'LUS' },
  { key: 'BEY', text: 'BEY', value: 'BEY' },
  { key: 'IST', text: 'IST', value: 'IST' },
  { key: 'XMAU', text: 'XMAU', value: 'XMAU' },
  { key: 'ADX', text: 'ADX', value: 'ADX' },
  { key: 'DUB', text: 'DUB', value: 'DUB' },
  { key: 'UKEX', text: 'UKEX', value: 'UKEX' },
  { key: 'XTRN', text: 'XTRN', value: 'XTRN' },
  { key: 'BUD', text: 'BUD', value: 'BUD' },
  { key: 'XTUN', text: 'XTUN', value: 'XTUN' },
  { key: 'XAMS', text: 'XAMS', value: 'XAMS' },
  { key: 'XKRX', text: 'XKRX', value: 'XKRX' },
  { key: 'MNT', text: 'MNT', value: 'MNT' },
  { key: 'BOG', text: 'BOG', value: 'BOG' },
  { key: 'XSEC', text: 'XSEC', value: 'XSEC' },
  { key: 'IPSX', text: 'IPSX', value: 'IPSX' },
  { key: 'QUI', text: 'QUI', value: 'QUI' },
  { key: 'MSW', text: 'MSW', value: 'MSW' },
  { key: 'NAI', text: 'NAI', value: 'NAI' },
  { key: 'MUN', text: 'MUN', value: 'MUN' },
  { key: 'ARCA', text: 'ARCA', value: 'ARCA' },
  { key: 'LTS', text: 'LTS', value: 'LTS' },
  { key: 'HAN', text: 'HAN', value: 'HAN' },
  { key: 'KAR', text: 'KAR', value: 'KAR' },
  { key: 'MISX', text: 'MISX', value: 'MISX' },
  { key: 'XPTY', text: 'XPTY', value: 'XPTY' },
  { key: 'XTER', text: 'XTER', value: 'XTER' },
  { key: 'SHSC', text: 'SHSC', value: 'SHSC' },
  { key: 'DUS', text: 'DUS', value: 'DUS' },
  { key: 'MAL', text: 'MAL', value: 'MAL' },
  { key: 'BKK', text: 'BKK', value: 'BKK' },
  { key: 'XMAE', text: 'XMAE', value: 'XMAE' },
  { key: 'ASX', text: 'ASX', value: 'ASX' },
  { key: 'WBO', text: 'WBO', value: 'WBO' },
  { key: 'NZSE', text: 'NZSE', value: 'NZSE' },
  { key: 'XBRV', text: 'XBRV', value: 'XBRV' },
  { key: 'MUS', text: 'MUS', value: 'MUS' },
  { key: 'XLJU', text: 'XLJU', value: 'XLJU' },
  { key: 'AMEX', text: 'AMEX', value: 'AMEX' },
  { key: 'XKLS', text: 'XKLS', value: 'XKLS' },
  { key: 'XKAZ', text: 'XKAZ', value: 'XKAZ' },
  { key: 'NSE', text: 'NSE', value: 'NSE' },
  { key: 'DAR', text: 'DAR', value: 'DAR' },
  { key: 'XNEP', text: 'XNEP', value: 'XNEP' },
  { key: 'DFM', text: 'DFM', value: 'DFM' },
  { key: 'ISX', text: 'ISX', value: 'ISX' },
  { key: 'XSAT', text: 'XSAT', value: 'XSAT' },
  { key: 'XZIM', text: 'XZIM', value: 'XZIM' },
  { key: 'LSE', text: 'LSE', value: 'LSE' },
  { key: 'IQS', text: 'IQS', value: 'IQS' },
  { key: 'OHEL', text: 'OHEL', value: 'OHEL' },
  { key: 'SZSE', text: 'SZSE', value: 'SZSE' },
  { key: 'CAI', text: 'CAI', value: 'CAI' },
  { key: 'DIFX', text: 'DIFX', value: 'DIFX' },
  { key: 'ORSE', text: 'ORSE', value: 'ORSE' },
  { key: 'BATS', text: 'BATS', value: 'BATS' },
  { key: 'XBLB', text: 'XBLB', value: 'XBLB' },
  { key: 'BSE', text: 'BSE', value: 'BSE' },
  { key: 'LUX', text: 'LUX', value: 'LUX' },
  { key: 'OTCPK', text: 'OTCPK', value: 'OTCPK' },
  { key: 'ARM', text: 'ARM', value: 'ARM' },
  { key: 'XLIS', text: 'XLIS', value: 'XLIS' },
  { key: 'HAM', text: 'HAM', value: 'HAM' },
  { key: 'MIL', text: 'MIL', value: 'MIL' },
  { key: 'SWA', text: 'SWA', value: 'SWA' },
  { key: 'SGX', text: 'SGX', value: 'SGX' },
  { key: 'NAM', text: 'NAM', value: 'NAM' },
  { key: 'AMM', text: 'AMM', value: 'AMM' },
  { key: 'NAS', text: 'NAS', value: 'NAS' },
  { key: 'STU', text: 'STU', value: 'STU' },
  { key: 'BOT', text: 'BOT', value: 'BOT' },
  { key: 'XSGO', text: 'XSGO', value: 'XSGO' },
  { key: 'PFTS', text: 'PFTS', value: 'PFTS' },
  { key: 'CAS', text: 'CAS', value: 'CAS' },
  { key: 'NEXX', text: 'NEXX', value: 'NEXX' },
  { key: 'NSA', text: 'NSA', value: 'NSA' },
  { key: 'TSE', text: 'TSE', value: 'TSE' },
  { key: 'BER', text: 'BER', value: 'BER' },
  { key: 'NYSE', text: 'NYSE', value: 'NYSE' },
  { key: 'OSL', text: 'OSL', value: 'OSL' },
  { key: 'XNEC', text: 'XNEC', value: 'XNEC' },
  { key: 'BUE', text: 'BUE', value: 'BUE' },
  { key: 'XSSC', text: 'XSSC', value: 'XSSC' },
  { key: 'XCNQ', text: 'XCNQ', value: 'XCNQ' },
  { key: 'SSE', text: 'SSE', value: 'SSE' },
  { key: 'COL', text: 'COL', value: 'COL' },
  { key: 'ATH', text: 'ATH', value: 'ATH' },
  { key: 'XGUA', text: 'XGUA', value: 'XGUA' },
  { key: 'DSMD', text: 'DSMD', value: 'DSMD' },
  { key: 'XGHA', text: 'XGHA', value: 'XGHA' },
  { key: 'ROCO', text: 'ROCO', value: 'ROCO' },
  { key: 'XPAE', text: 'XPAE', value: 'XPAE' },
  { key: 'OISE', text: 'OISE', value: 'OISE' },
  { key: 'HSTC', text: 'HSTC', value: 'HSTC' },
  { key: 'OCSE', text: 'OCSE', value: 'OCSE' },
  { key: 'HKSE', text: 'HKSE', value: 'HKSE' },
  { key: 'FRA', text: 'FRA', value: 'FRA' },
  { key: 'XMAD', text: 'XMAD', value: 'XMAD' },
  { key: 'NGO', text: 'NGO', value: 'NGO' },
  { key: 'XJAM', text: 'XJAM', value: 'XJAM' },
  { key: 'XBRA', text: 'XBRA', value: 'XBRA' },
  { key: 'CHIX', text: 'CHIX', value: 'CHIX' },
  { key: 'XMNX', text: 'XMNX', value: 'XMNX' },
  { key: 'BDA', text: 'BDA', value: 'BDA' },
  { key: 'DHA', text: 'DHA', value: 'DHA' },
  { key: 'XBRU', text: 'XBRU', value: 'XBRU' },
  { key: 'TSXV', text: 'TSXV', value: 'TSXV' },
  { key: 'TPE', text: 'TPE', value: 'TPE' },
  { key: 'ZAG', text: 'ZAG', value: 'ZAG' },
  { key: 'XTAE', text: 'XTAE', value: 'XTAE' },
  { key: 'BVMF', text: 'BVMF', value: 'BVMF' },
  { key: 'BOM', text: 'BOM', value: 'BOM' },
  { key: 'TSX', text: 'TSX', value: 'TSX' },
  { key: 'XSWX', text: 'XSWX', value: 'XSWX' },
  { key: 'SZSC', text: 'SZSC', value: 'SZSC' },
  { key: 'FSE', text: 'FSE', value: 'FSE' },
  { key: 'CYS', text: 'CYS', value: 'CYS' },
  { key: 'WAR', text: 'WAR', value: 'WAR' },
  { key: 'BAH', text: 'BAH', value: 'BAH' },
  { key: 'XPRA', text: 'XPRA', value: 'XPRA' },
  { key: 'OTSE', text: 'OTSE', value: 'OTSE' },
  { key: 'BVCA', text: 'BVCA', value: 'BVCA' },
  { key: 'XPAR', text: 'XPAR', value: 'XPAR' },
  { key: 'XBUL', text: 'XBUL', value: 'XBUL' },
  { key: 'XBOL', text: 'XBOL', value: 'XBOL' },
  { key: 'LIM', text: 'LIM', value: 'LIM' },
  { key: 'SAU', text: 'SAU', value: 'SAU' },
  { key: 'JSE', text: 'JSE', value: 'JSE' },
  { key: 'GREY', text: 'GREY', value: 'GREY' },
  { key: 'XBEL', text: 'XBEL', value: 'XBEL' },
  { key: 'STC', text: 'STC', value: 'STC' },
  { key: 'MEX', text: 'MEX', value: 'MEX' },
  { key: 'XTEH', text: 'XTEH', value: 'XTEH' },
];
export const INVESTMENT_TYPE_LISTING = 'listing';
export const INVESTMENT_TYPE_SHARECLASS = 'share_class';
export const INVESTMENT_TYPES = [
  { text: 'Listing', value: INVESTMENT_TYPE_LISTING },
  { text: 'Share Class', value: INVESTMENT_TYPE_SHARECLASS },
  // { display: 'Company', name: 'company' },
  // { display: 'Fund', name: 'fund' },
];
export interface FileType {
  extension: string;
  mimeType: string;
  thumbnailSource?: string;
}
export const S3_UPLOAD_FILE_TYPES_ALLOWED: Array<FileType> = [
  {
    extension: '.txt',
    mimeType: 'text/plain',
    thumbnailSource: `${process.env.BASE_URL}assets/text.gif`,
  },
  {
    extension: '.pdf',
    mimeType: 'application/pdf',
    thumbnailSource: `${process.env.BASE_URL}assets/pdf2.gif`,
  },
  // Images
  //{ extension: '.jpg', mimeType: 'image/jpeg' },
  //{ extension: '.jpeg', mimeType: 'image/jpeg' },
  //{ extension: '.gif', mimeType: 'image/gif' },
  //{ extension: '.png', mimeType: 'image/png' },
  //{ extension: '.tif', mimeType: 'image/tiff' },
  //{ extension: '.tiff', mimeType: 'image/tiff' },
  // Word
  {
    extension: '.doc',
    mimeType: 'application/msword',
    thumbnailSource: `${process.env.BASE_URL}assets/word.gif`,
  },
  //{ extension: '.dot', mimeType: 'application/msword' },
  {
    extension: '.docx',
    mimeType:
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    thumbnailSource: `${process.env.BASE_URL}assets/word.gif`,
  },
  //{ extension: '.dotx', mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.template' },
  //{ extension: '.docm', mimeType: 'application/vnd.ms-word.document.macroEnabled.12' },
  //{ extension: '.dotm', mimeType: 'application/vnd.ms-word.template.macroEnabled.12' },
  // Excel
  {
    extension: '.xls',
    mimeType: 'application/vnd.ms-excel',
    thumbnailSource: `${process.env.BASE_URL}assets/excel.gif`,
  },
  //{ extension: '.xlt', mimeType: 'application/vnd.ms-excel' },
  //{ extension: '.xla', mimeType: 'application/vnd.ms-excel' },
  {
    extension: '.xlsx',
    mimeType:
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    thumbnailSource: `${process.env.BASE_URL}assets/excel.gif`,
  },
  //{ extension: '.xltx', mimeType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.template' },
  //{ extension: '.xlsm', mimeType: 'application/vnd.ms-excel.sheet.macroEnabled.12' },
  //{ extension: '.xltm', mimeType: 'application/vnd.ms-excel.template.macroEnabled.12' },
  //{ extension: '.xlam', mimeType: 'application/vnd.ms-excel.addin.macroEnabled.12' },
  //{ extension: '.xlsb', mimeType: 'application/vnd.ms-excel.sheet.binary.macroEnabled.12' },
  // Powerpoint
  {
    extension: '.ppt',
    mimeType: 'application/vnd.ms-powerpoint',
    thumbnailSource: `${process.env.BASE_URL}assets/ppt.gif`,
  },
  // { extension: '.pot', mimeType: 'application/vnd.ms-powerpoint' },
  // { extension: '.pps', mimeType: 'application/vnd.ms-powerpoint' },
  // { extension: '.ppa', mimeType: 'application/vnd.ms-powerpoint' },
  {
    extension: '.pptx',
    mimeType:
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    thumbnailSource: `${process.env.BASE_URL}assets/ppt.gif`,
  },
  // { extension: '.potx', mimeType: 'application/vnd.openxmlformats-officedocument.presentationml.template' },
  // { extension: '.ppsx', mimeType: 'application/vnd.openxmlformats-officedocument.presentationml.slideshow' },
  // { extension: '.ppam', mimeType: 'application/vnd.ms-powerpoint.addin.macroEnabled.12' },
  // { extension: '.pptm', mimeType: 'application/vnd.ms-powerpoint.presentation.macroEnabled.12' },
  // { extension: '.potm', mimeType: 'application/vnd.ms-powerpoint.template.macroEnabled.12' },
  // { extension: '.ppsm', mimeType: 'application/vnd.ms-powerpoint.slideshow.macroEnabled.12' },
];
