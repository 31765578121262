
import Vue from 'vue';
import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid';
import MdsSection from '@mds/section';
import MdsLoader from '@mds/loader';
import MdsForm from '@mds/form';
import MdsInput from '@mds/input';
import { MdsButton } from '@mds/button';
import UIApplicationHelper from 'Utils/UIApplicationHelper';
import Logger from 'Utils/Logger';
import {
  downloadFromS3,
  formatISODate,
  S3DownloadResponse,
  S3UploadResponse,
  uploadToS3,
} from 'Utils/s3Helper';
import { BytesToMegaBytes } from 'Utils/ObjectUtilities';
import join from 'lodash/join';
import find from 'lodash/find';
import map from 'lodash/map';
import { S3_UPLOAD_FILE_TYPES_ALLOWED } from 'Constants/index';
import { log } from '@/js/powerup';

export default Vue.extend({
  name: 'PowerupSearchEditComponent',
  components: {
    MdsLayoutGrid,
    MdsRow,
    MdsCol,
    MdsSection,
    MdsLoader,
    MdsForm,
    MdsButton,
    MdsInput,
  },
  created: async function() {
    if (this.$route.query.debug !== undefined) {
      if (this.$route.query.debug === 'true') {
        this.debug = true;
        log('DEBUG is on.', 'info', null);
      }
    }
    if (this.debug) log('created', 'life');
    if (this.debug) log(`route: ${this.$route.path}`, 'debug');
    if (this.$route.path === '/powerup/edit') {
      if (this.debug) log('this.edit == true', 'info');
      this.edit = true;
    }

    // check for k
    if (this.$route.query.k === undefined) {
      if (this.debug)
        log('query does not contain the proper query identifier (k)', 'warn');
    } else {
      this.k = this.$route.query.k as string;
    }

    // if page is edit, check for p
    if (this.edit && this.$route.query.p === undefined) {
      if (this.debug)
        log(
          'query does not contain the proper query identifier for /powerup/edit/ (p)',
          'warn',
        );
    } else {
      this.p = this.$route.query.p as string;
    }
  },
  beforeMount: async function () {
    this.logger = UIApplicationHelper.getLogger();
    this.logger.trace('Logger has been loaded for file search edit component.');
    this.error = '';

    if (this.p) {
      const customEmbed = JSON.parse(this.p);
      if (customEmbed) {
        this.customEmbedId = customEmbed.id;
        this.fileName = customEmbed.config.file_name;
        this.url = customEmbed.url;
        this.lastModified = formatISODate(
          customEmbed.config.last_modified_date,
        );
        downloadFromS3(customEmbed.url)
          .then((resp) => {
            if (resp.getUrl) {
              this.downloadUrl = resp.getUrl;
            }
          })
          .catch((reason: S3DownloadResponse) => {
            this.logger.error('Error loading file url.', reason);
            this.error = reason.message || 'Error loading file url.';
          })
          .finally(() => {
            this.isLoading = false;
            //this.$emit('powerup-ready');
          });
      }
    } else {
      this.isLoading = false;
    }
  },
    mounted: async function() {
    if (this.debug) log('mounted', 'life');
  },
  data() {
    return {
      logger: {} as Logger,
      isLoading: true,
      error: '',
      p: 'p',
      k: 'k',
      debug: false,
      edit: false,
      resultsLoading: false,
      customEmbedId: '',
      fileName: '',
      url: '',
      downloadUrl: '',
      lastModified: '',
      filesToUpload: null as FileList | null,
      fileUploadInputErrors: [] as string[],
    };
  },
  computed: {
    title(): string {
      if (this.customEmbedId === '') {
        return 'Attach File';
      } else {
        return 'Edit File';
      }
    },
    fileUploadInputIsError(): boolean {
      return this.fileUploadInputErrors.length > 0;
    },
    allowedFileTypes(): string {
      return join(map(S3_UPLOAD_FILE_TYPES_ALLOWED, 'mimeType'), ', ');
    },
    fileThumbnailSource(): string {
      if (this.fileName !== '') {
        const found = find(S3_UPLOAD_FILE_TYPES_ALLOWED, [
          'extension',
          `.${this.fileName.split('.').pop()}`,
        ]);
        if (found) {
          return found.thumbnailSource || '';
        }
      }
      return '';
    },
  },
  methods: {
    resetErrors() {
      this.fileUploadInputErrors = [];
      this.error = '';
    },
    filesChange(event: Event) {
      const vm = this;
      vm.resultsLoading = true;
      vm.resetErrors();

      vm.logger.info('Files selected.', event);
      const files = (event.target as HTMLInputElement).files;
      const errors = vm.validateFiles(files);
      if (errors.length === 0) {
        vm.filesToUpload = (event.target as HTMLInputElement).files;
      } else {
        vm.fileUploadInputErrors.push(...errors);
      }
      vm.resultsLoading = false;
    },
    onUpload(event: Event) {
      const vm = this;
      vm.logger.info('File upload clicked.', event);
      vm.resetErrors();

      const errors = vm.validateFiles(vm.filesToUpload);
      if (errors.length === 0 && vm.filesToUpload !== null) {
        vm.uploadFile(vm.filesToUpload[0]);
      } else {
        vm.fileUploadInputErrors.push(...errors);
      }
    },
    validateFiles(files: FileList | null): string[] {
      const errors: string[] = [];
      if (files !== null) {
        if (files.length === 1) {
          const fileToUpload = files[0];
          const foundType = find(S3_UPLOAD_FILE_TYPES_ALLOWED, [
            'mimeType',
            fileToUpload.type,
          ]);
          if (!foundType) {
            this.logger.warn('File type is not allowed.', fileToUpload.type);
            errors.push('File type is not allowed.');
          } else if (BytesToMegaBytes(fileToUpload.size) > 100) {
            this.logger.warn(
              'File size is greater than 100 MB.',
              BytesToMegaBytes(fileToUpload.size),
            );
            errors.push('Max size is 100MB to upload a file.');
          }
        } else {
          this.logger.warn(
            'Different than 1 file selected for validation.',
            files,
          );
          errors.push('You must select only 1 file before uploading.');
        }
      } else {
        this.logger.warn('Upload clicked without files selected.');
        errors.push('You must select 1 file before uploading.');
      }
      return errors;
    },
    uploadFile(file: File) {
      const vm = this;
      vm.resultsLoading = true;
      uploadToS3(file)
        .then((resp: S3UploadResponse) => {
          if (resp.message === undefined) {
            vm.logger.info('Success uploading file to S3', resp);
            vm.$emit('file-uploaded', resp);
          } else {
            vm.logger.error('Error uploading file to S3', resp);
            vm.error = `There was an error uploading file to S3: ${resp.message}.`;
          }
        })
        .catch((error: unknown) => {
          vm.logger.error('Unexpected error uploading file to S3', error);
          if (error instanceof Error) {
            vm.error = `There was an unexpected error uploading file to S3: ${error.message}.`;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } else if ((error as any).message !== undefined) {
            vm.error = `There was an unexpected uploading file to S3: ${
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (error as any).message
            }.`;
          } else {
            vm.error = `There was an unexpected error uploading file to S3.`;
          }
        })
        .finally(() => {
          vm.resultsLoading = false;
        });
    },
  },
});
