import {
  INVESTMENT_TYPE_LISTING,
  INVESTMENT_TYPE_SHARECLASS } from '@/constants';
import request from './api-wrapper';

export interface QueryObject {
  q: string;
  take: number;
  skip: number;
  name?: string;
  ec?: Array<string>;
}

export interface QueryResponseObject<
  T = CompanyObject | ShareclassObject | PerformanceObject | FundObject,
> {
  data: Array<T>;
  errors: Array<string> | null;
  totalCount: number;
}
export interface CompanyObject {
  id: string; //company_id
  standardName: string; //standard_name
  datalakeUpdateDateUtc: string; //datalake_update_date_utc
  countryCode: string; //domicile_country_code
  updateDateUtc: string; //update_date_utc
}
export interface ShareclassObject {
  id: string; //share_class_id
  standardName: string; //standard_name
  symbol: string; //symbol
  countryCode: string; //domicile_country_code
  datalakeUpdateDateUtc: string; //datalake_update_date_utc
  updateDateUtc: string; //update_date_utc
}
export interface PerformanceObject {
  id: string; //share_class_id
  standardName: string; //standard_name
  symbol: string; //symbol
  exchangeCode: string; //exchange_code
  datalakeUpdateDateUtc: string; //datalake_update_date_utc
  updateDateUtc: string; //update_date_utc
}
export interface FundObject {
  id: string; //managed_investment_id
  standardName: string; //standard_name
  countryCode: string; //country_code
  datalakeUpdateDateUtc: string; //datalake_update_date_utc
  updateDateUtc: string; //update_date_utc
}
export const instanceOfQueryResponseObject = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  object: any,
): object is QueryResponseObject => {
  return 'data' in object && 'errors' in object && 'totalCount' in object;
};

export const getCompany = (
  com: QueryObject,
): Promise<QueryResponseObject<CompanyObject>> => {
  const params = com.q;
  return request({
    method: 'GET',
    url: `/api/security/companies?q=${params}&take=${com.take}&skip=${com.skip}`,
  }) as Promise<QueryResponseObject<CompanyObject>>;
};

export const getFund = (
  fund: QueryObject,
): Promise<QueryResponseObject<FundObject>> =>
  request({
    method: 'GET',
    url: `//api/securityfunds?q=${fund.q}&take=${fund.take}&skip=${fund.skip}`,
  }) as Promise<QueryResponseObject<FundObject>>;

export const getListing = (
  performance: QueryObject,
): Promise<QueryResponseObject<PerformanceObject>> =>
  request({
    method: 'GET',
    url: `/api/security/performance?q=${performance.q}&take=${
      performance.take
    }&skip=${performance.skip}${
      performance.ec && performance.ec.length > 0
        ? `&ec=${performance.ec.join(',')}`
        : ''
    }`,
  }) as Promise<QueryResponseObject<PerformanceObject>>;

export const getShareclass = (
  shareclass: QueryObject,
): Promise<QueryResponseObject<ShareclassObject>> =>
  request({
    method: 'GET',
    url: `/api/security/shareclasses?q=${shareclass.q}&take=${shareclass.take}&skip=${shareclass.skip}`,
  }) as Promise<QueryResponseObject<ShareclassObject>>;

const getData = (
  type: string,
  obj: QueryObject,
): Promise<
  QueryResponseObject<
    CompanyObject | ShareclassObject | PerformanceObject | FundObject
  >
> => {
  switch (type) {
    case 'company':
      return getCompany(obj);
    case 'fund':
      return getFund(obj);
    case INVESTMENT_TYPE_LISTING:
      return getListing(obj);
    case INVESTMENT_TYPE_SHARECLASS:
      return getShareclass(obj);
    default:
      throw `Query type '${type}' is not valid`;
  }
};

const requestData = (
  type: string,
  obj: QueryObject,
): Promise<
  QueryResponseObject<
    CompanyObject | ShareclassObject | PerformanceObject | FundObject
  >
> => {
  if (obj) {
    return getData(type, obj);
  }
  throw 'No query object passed to request';
};

export default requestData;
