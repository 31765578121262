export default {
  data: function () {
    return {
      PPT_ID: 'id',
      PPT_NAME: 'name',
      PPT_DESCRIPTION: 'description',
      PPT_READ_ONLY: 'readOnly',
      PPT_LABEL: 'label',
      PPT_ATTRIBUTES: 'attributes',
      PPT_RELATIONSHIPS: 'relationships',
      PPT_RELATIONSHIPS_NUMBER: 'relationships_number',
      PPT_TARGET_CLASS: 'targetClass',
      PPT_IDENTIFIER: 'identifier',
      PPT_TYPE: 'type',
      PPT_PREDEFINED_LIST: 'predefinedList',
      PPT_REQUIRED: 'required',
      PPT_SINGLE_VALUE: 'singleValue',
      PPT_DATA_SOURCE: 'dataSource',
      PPT_PUBLISH_DATE: 'publishDate',
      PPT_UPDATE_DATE: 'updateDate',
      PPT_SOURCE_UPDATE_DATE: 'sourceUpdateDate',
      PPT_DOC_ID: 'docId',
      REQUIRED_MSG: 'This field is required',
      PPT_TRANSLATION: 'translation',
      PPT_TRANSLATED: 'translated',
      PPT_DISCUSSED_IN: 'discussedIn',
      PPT_USE_CASE_TEXT: 'useCaseText',
      PPT_DEFINITION_TEXT: 'definitionText',
      PPT_USE_CASE_LABEL: 'Use Case',
      PPT_DEFINITION_LABEL: 'Definition',
      ATTRIBUTE_HEADER: [
        {
          value: 'name',
          text: 'Name',
          type: 'dropdown',
        },
        {
          value: 'type',
          text: 'Type',
          type: 'dropdown',
          options: [
            { text: 'Free Text', value: 'Free Text' },
            { text: 'Structured Text', value: 'Structured Text' },
            { text: 'URL', value: 'URL' },
            { text: 'Integer', value: 'Integer' },
            { text: 'Double', value: 'Double' },
            { text: 'DateTime', value: 'DateTime' },
            { text: 'Bool', value: 'Bool' },
          ],
        },
        {
          value: 'required',
          text: 'Required',
          type: 'checkbox',
        },
        {
          value: 'singleValue',
          text: 'Single Value',
          type: 'checkbox',
        },
        {
          value: 'predefinedList',
          text: 'Predefined List',
          type: 'expandableText',
        },
      ],
      RELATIONSHIP_HEADER: [
        {
          value: 'name',
          text: 'Name',
          type: 'text',
        },
        {
          value: 'targetClass',
          text: 'Target Class',
          type: 'dropdown',
        },
        {
          value: 'nameInTarget',
          text: 'Name In Target',
          type: 'text',
        },
        {
          value: 'required',
          text: 'Required',
          type: 'checkbox',
        },
        {
          value: 'requiredInTarget',
          text: 'Required In Target',
          type: 'checkbox',
        },
      ],
      REL_CONCEPT: 'concept',
      REL_ARTICLE: 'article',
      REL_VIDEO: 'video',
      REL_COUNTRY: 'country',
      SCHEMA_UNEDITABLE_FIELDS: ['dataSource', 'updateDate'],

      REL_COUNTRY_SKIPPED_RELATIONS: ['company', 'stock', 'shareClass'],

      TAXONOMY_ATTRIBUTE_LABEL: {
        marketsArticleCategory: 'Markets Article Category',
      },
      LOCALES: ['en-US', 'en-CA', 'it-IT', 'fr-CA', 'en-GB', 'fr-FR', 'de-DE', 'es-ES', 'nl-NL']
    };
  },
};
