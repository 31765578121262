import Auth from '@okta/okta-vue';
import AuthorDisclosurePowerup from 'Components/authorDisclosures/PowerupSearchEditComponent.vue';
import AuthorDisclosureView from 'Views/authorDisclosures/PowerupViewComponent.vue';
import FilesPowerup from 'Components/files/PowerupSearchEditComponent.vue';
import FilesView from 'Views/files/PowerupViewComponent.vue';
import InvestmentPowerup from 'Components/investment/PowerupSearchEditComponent.vue';
import InvestmentView from 'Views/investment/PowerupViewComponent.vue';
import TaxonomySearchEditComponent from 'Components/Taxonomy/Powerup/PowerupSearchEditComponent.vue';
import TaxonomyViewComponent from 'Views/taxonomy/PowerupViewComponent.vue';
import PowerupHierarchy from 'Components/Taxonomy/PowerupHierarchy/PowerupHierarchy.vue';
import Router from 'vue-router';
import Vue from 'vue';

Vue.use(Router);
const requiresAuth = process.env.VUE_APP_REQUIRES_AUTH !== 'false';

const router = new Router({
  mode: 'history',
  routes: [
    // author disclosures
    {
      path: '/authorDisclosures/search',
      name: 'AuthorDisclosuresSearch',
      component: AuthorDisclosurePowerup,
      meta: { title: 'Author Disclosures Search', requiresAuth: requiresAuth },
    },
    {
      path: '/authorDisclosures/view',
      name: 'AuthorDisclosuresView',
      component: AuthorDisclosureView,
      meta: { title: 'Author Disclosures View', requiresAuth: requiresAuth },
    },
    {
      path: '/authorDisclosures/edit',
      name: 'AuthorDisclosuresEdit',
      component: AuthorDisclosurePowerup,
      meta: { title: 'Author Disclosures Edit', requiresAuth: requiresAuth },
    },
    // files upload
    {
      path: '/files/search',
      name: 'FileUploadSearch',
      component: FilesPowerup,
      meta: { title: 'File Upload Search', requiresAuth: requiresAuth },
    },
    {
      path: '/files/view',
      name: 'FileUploadView',
      component: FilesView,
      meta: { title: 'File Upload View', requiresAuth: requiresAuth },
    },
    {
      path: '/files/edit',
      name: 'FileUploadEdit',
      component: FilesPowerup,
      meta: { title: 'File Upload Edit', requiresAuth: requiresAuth },
    },
    // investment
    {
      path: '/investment/search',
      name: 'InvestmentSearch',
      component: InvestmentPowerup,
      meta: { title: 'Investment Search', requiresAuth: requiresAuth },
    },
    {
      path: '/investment/view',
      name: 'InvestmentView',
      component: InvestmentView,
      meta: { title: 'Investment View', requiresAuth: requiresAuth },
    },
    {
      path: '/investment/edit',
      name: 'InvestmentEdit',
      component: InvestmentPowerup,
      meta: { title: 'Investment Edit', requiresAuth: requiresAuth },
    },
    // taxonomy
    {
      path: '/taxonomy/view',
      name: 'TaxonomyView',
      component: TaxonomyViewComponent,
      meta: { title: 'Powerup View', requiresAuth: requiresAuth },
    },
    {
      path: '/taxonomy/search',
      name: 'TaxonomySearch',
      component: TaxonomySearchEditComponent,
      meta: { title: 'Powerup Search', requiresAuth: requiresAuth },
    },
    {
      path: '/taxonomy/edit',
      name: 'TaxonomyEdit',
      component: TaxonomySearchEditComponent,
      meta: { title: 'Powerup Edit', requiresAuth: requiresAuth },
    },
    {
      path: '/powerup-hierarchy/:conceptId?',
      name: 'PowerupHierarchy',
      component: PowerupHierarchy,
      meta: { title: 'Powerup Edit', requiresAuth: requiresAuth },
    },
    {
      path: '/login/callback',
      component: Auth.handleCallback(),
    },
  ],
});

const oidcConfig = {
  clientId: process.env.VUE_APP_OKTA_ID,
  issuer: 'https://morningstar.okta.com',
  redirectUri: `${process.env.VUE_APP_URL}login/callback`,
  scopes: ['openid', 'profile', 'email', 'groups'],
  pkce: true,
};

Vue.use(Auth, oidcConfig);

router.beforeEach(Vue.prototype.$auth.authRedirectGuard());

router.afterEach(to => {
  Vue.nextTick(() => {
    if (to.meta.hasOwnProperty('title')) {
      document.title = `${to.meta.title} | ${process.env.VUE_APP_TITLE}`;
    }
  });
});

export default router;
