<template>
  <div v-if="!pageHasError">
    <mds-loader v-if="isLoading" class="termview_center"></mds-loader>
    <div v-else-if="!termExists">Term {{ activeTerm }} does not exist.</div>
    <mds-section v-else border="none">
      <div class="title">
        <h2>{{ this.termObject.attributes.name }}</h2>
        <div class="icon-placeholder">
          <AppIconTooltipTextAndHover
            v-if="termObject[PPT_ATTRIBUTES][PPT_USE_CASE_TEXT]"
            icon="document"
            :tooltip="termObject[PPT_ATTRIBUTES][PPT_USE_CASE_TEXT]"
            :variation="'flat'"
            :text="PPT_USE_CASE_LABEL"
            :flatButtonSize="'medium'"
          />
          <AppIconTooltipAndText
            v-if="termObject[PPT_ATTRIBUTES][PPT_DEFINITION_TEXT]"
            icon="paperclip-angle"
            :tooltip="termObject[PPT_ATTRIBUTES][PPT_DEFINITION_TEXT]"
            :variation="'flat'"
            :text="PPT_DEFINITION_LABEL"
            :flatButtonSize="'medium'"
          />
        </div>
      </div>
      <a
        v-if="termObject[PPT_LABEL] === 'concept'"
        class="my-link"
        target="_blank"
        :href="hierarchyLink"
        >Browse Hierarchy</a
      >
      <div
        v-if="
          termObject.hasOwnProperty(PPT_ATTRIBUTES) &&
          termObject[PPT_ATTRIBUTES].hasOwnProperty(PPT_IDENTIFIER)
        "
      >
        <span class="bold">Identifer:</span>
        {{ termObject[PPT_ATTRIBUTES][PPT_IDENTIFIER] }}
      </div>
      <div>
        <span class="bold">Class:</span>
        {{ decamelize(termObject[PPT_LABEL]) }}
      </div>
      <div><span class="bold">Database ID:</span> {{ termObject[PPT_ID] }}</div>
      <!-- attributes -->
      <div v-for="(attribute, aIndex) in attributeSchema" :key="aIndex">
        <div v-if="![PPT_NAME, PPT_IDENTIFIER].includes(attribute[PPT_NAME])">
          <span class="bold">{{ attribute['description'] }}:</span>
          <TermViewAttribute
            v-if="
              termObject.hasOwnProperty(PPT_ATTRIBUTES) &&
              termObject[PPT_ATTRIBUTES].hasOwnProperty(attribute[PPT_NAME])
            "
            :value="termObject[PPT_ATTRIBUTES][attribute[PPT_NAME]]"
            :type="attribute['type']"
            :singleValue="attribute['singleValue']"
            :name="attribute[PPT_NAME]"
          />
          <span v-else> None </span>
        </div>
      </div>
      <!-- relationships -->
      <div
        v-for="(relationship, index) in relationshipSchema"
        :key="`${relationship[PPT_NAME]}${index}`"
      >
        <div class="bold">{{ relationship['description'] }}:</div>
        <div v-if="relationship[PPT_NAME] === PPT_DISCUSSED_IN">
          <mds-select
            label="Locale"
            :options="locales"
            v-model="selectedLocale"
          ></mds-select>
        </div>
        <div class="mds-doc-typography">
          <ul
            style="list-style: none"
            v-for="label in relationship['targetClasses']"
            :key="`${relationship[PPT_NAME]}${label}`"
          >
            <li
              :class="{
                'hide-marker': relationship['targetClasses'].length <= 1,
              }"
            >
              <div v-if="relationship['targetClasses'].length > 1">
                {{ decamelize(label) }}
              </div>
              <div
                v-if="
                  termObject.hasOwnProperty(PPT_RELATIONSHIPS) &&
                  termObject[PPT_RELATIONSHIPS].hasOwnProperty(
                    relationship[PPT_NAME],
                  ) &&
                  termObject[PPT_RELATIONSHIPS][
                    relationship[PPT_NAME]
                  ].hasOwnProperty(label)
                "
              >
                <ul
                  style="list-style: none"
                  v-for="(relationshipItem, relationshipIndex) in termObject[
                    PPT_RELATIONSHIPS
                  ][relationship[PPT_NAME]][label]"
                  :key="relationshipIndex"
                >
                  <li>
                    {{ relationshipItem.name }}
                  </li>
                </ul>
                <mds-pagination
                  show-items-info
                  v-if="
                    termObject[PPT_RELATIONSHIPS_NUMBER][
                      relationship[PPT_NAME]
                    ][label] > pageSize
                  "
                  :total-items="
                    termObject[PPT_RELATIONSHIPS_NUMBER][
                      relationship[PPT_NAME]
                    ][label]
                  "
                  :pageSize="pageSize"
                  @mds-pagination-page-changed="
                    pageChanged(relationship[PPT_NAME], label, $event)
                  "
                ></mds-pagination>
              </div>
              <div v-else>
                None
                <span
                  v-if="
                    relationship[PPT_NAME] === 'narrowerTerms' &&
                    termObject[PPT_ATTRIBUTES]['classified']
                  "
                >
                  (End Node)
                </span>
                <span
                  v-if="
                    relationship[PPT_NAME] === 'broaderTerms' &&
                    termObject[PPT_ATTRIBUTES]['classified']
                  "
                >
                  (Top Node)
                </span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </mds-section>
  </div>
  <div v-else class="termview_center">
    There was an error loading the page. Please ensure that you are on the VPN.
  </div>
</template>

<script>
import Constants from '@/js/constants.js';
import Request from '@/js/request.js';
import MdsLoader from '@mds/loader';
import MdsPagination from '@mds/pagination';
import MdsSection from '@mds/section';
import MdsSelect from '@mds/select';
import TermViewAttribute from '../TermManagement/TermViewAttribute.vue';
import AppIconTooltipAndText from '@/components/Taxonomy/elements/AppIconTooltipAndText.vue';
import AppIconTooltipTextAndHover from '@/components/Taxonomy/elements/AppIconTooltipTextAndHover.vue';
import Utils from '@/js/utils.js';
import _ from 'underscore';

export default {
  name: 'TermView',
  components: {
    TermViewAttribute,
    MdsLoader,
    MdsPagination,
    MdsSection,
    MdsSelect,
    AppIconTooltipAndText,
    AppIconTooltipTextAndHover,
  },
  mixins: [Constants, Utils],
  props: {
    termId: String,
    debug: Boolean,
  },
  /** lifecycle: beforeCreate, created, beforeMount, mounted, beforeUpdate, updated, beforeUnmount, unmounted */
  /**
   * Calls loadTerm (which is called every time a term changes)
   */
  beforeMount: async function () {
    this.loadLocales();
    this.loadTerm(this.selectedLocale);
  },
  data() {
    return {
      hierarchyURI: process.env.VUE_APP_URL + 'powerup-hierarchy/',
      termObject: null,
      attributeSchema: null,
      relationshipSchema: null,
      isLoading: true,
      pageSize: 20,
      pageHasError: false,
      selectedLocale: 'en-US',
      locales: [],
    };
  },
  methods: {
    /**
     * Called every time a term is called or changed.
     */
    async loadTerm(activeLocale) {
      this.isLoading = true;
      try {
        await this.$store.dispatch('getTermById', {
          termId: this.activeTerm,
          refresh: this.ignoreCache,
          locale: activeLocale
        });
        this.termObject = this.$store.state.term.termObject;

        if (!this.termObject) {
          this.isLoading = false;
          return;
        }
        for (let key of Object.keys(this.termObject[this.PPT_RELATIONSHIPS])) {
          if (
            key === 'narrowerTerms' ||
            key === 'broaderTerms' ||
            key === 'relatedTerm'
          ) {
            this.termObject['relationships'][key]['concept'].sort((a, b) =>
              a.name.localeCompare(b.name),
            );
          }
        }
        this.attributeSchema =
          this.$store.state.term.termSchema[this.PPT_ATTRIBUTES];

        this.attributeSchema = this.attributeSchema.filter(
          item =>
            item.name !== this.PPT_USE_CASE_TEXT &&
            item.name !== this.PPT_DEFINITION_TEXT,
        );

        let relationships =
          this.$store.state.term.termSchema[this.PPT_RELATIONSHIPS];

        if (this.termObject.label === this.REL_COUNTRY) {
          relationships = _.filter(
            relationships,
            r =>
              !this.REL_COUNTRY_SKIPPED_RELATIONS.includes(
                r[this.PPT_TARGET_CLASS],
              ),
          );
        }

        const relationshipSchema = [];
        relationships.forEach(element => {
          const index = relationshipSchema.findIndex(
            r => r[this.PPT_NAME] === element[this.PPT_NAME],
          );

          if (index === -1) {
            relationshipSchema.push({
              targetClasses: [element[this.PPT_TARGET_CLASS]],
              name: element[this.PPT_NAME],
              description: element[this.PPT_DESCRIPTION],
            });
          } else {
            relationshipSchema[index]['targetClasses'].push(
              element[this.PPT_TARGET_CLASS],
            );
          }
        });

        this.relationshipSchema = relationshipSchema;
      } catch (error) {
        console.error(error);
        this.pageHasError = true;
      }
      this.isLoading = false;
    },
    /**
     * When pagination changes.
     */
    async pageChanged(relationshipName, label, payload) {
      try {
        const request = {
          id: this.termObject[this.PPT_ID],
          edge: relationshipName,
          vertex: label,
          start: payload['firstItem'],
          end: payload['lastItem'],
          locale: this.selectedLocale,
          refresh: this.ignoreCache,
        };
        await this.$store.dispatch('getRelationshipPagination', request);
      } catch (error) {
        this.pageHasError = true;
      }
    },
    async loadLocales() {
      for(let locale of this.LOCALES) {
        this.locales.push({
          value: locale,
          text: locale
        })
      }
    },
  },
  computed: {
    hierarchyLink() {
      return this.hierarchyURI + this.activeTerm;
    },
    termExists() {
      return this.termObject !== null;
    },
    activeTerm() {
      return this.termId;
    },
  },
  watch: {
    async activeTerm() {
      await this.loadTerm(this.selectedLocale);
    },
    async selectedLocale() {
      await this.loadTerm(this.selectedLocale);
    }
  },
};
</script>

<style lang="scss" scoped>
@import '@/style/global.scss';
@import '@mds/link';
.icon-placeholder {
  span {
    padding-right: #{$mds-space-1-x};
  }
  span:last-child,
  span:nth-last-child(2) {
    padding-right: 0px;
  }
}

.title {
  display: flex;
  align-items: center;
  justify-content: left;

  h2 {
    padding-right: #{$mds-space-half-x};
  }
}
.my-link {
  @include mds-link();
  font-size: 0.8rem;
}
.termview_center {
  position: absolute;
  top: 50%;
  left: 75%;
  transform: translate(-50%, -50%);
}
.mds-doc-typography ul {
  line-height: 26px;
  list-style: none;
  margin: 0;
  padding-left: 28px;
  position: relative;
  margin-bottom: 4px;
  max-width: 800px;
}
.mds-doc-typography ul ul {
  list-style-type: none;
  margin: 0;
  padding-left: 28px;
  position: relative;
}
.mds-doc-typography ul li::before {
  color: #ababab;
  content: '\2022';
  font-size: 16px;
  left: 0;
  line-height: 26px;
  position: absolute;
  vertical-align: baseline;
}
.hide-marker::before {
  display: none;
}
.mds-doc-typography ul li ul li::before {
  content: '\25CB';
  font-size: 16px;
  left: 0;
  line-height: 26px;
  position: absolute;
  vertical-align: baseline;
}
</style>
