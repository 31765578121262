import Vue from 'vue';
import Vuex from 'vuex';
import schema from './schema';
import term from './term';
import user from './user';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    schema,
    term,
    user,
  },
});
