// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import regeneratorRuntime from 'regenerator-runtime';

export interface S3UploadResponse {
  message?: string;
  s3Key?: string;
  lastModifiedDate?: string;
  fileName?: string;
}

export interface S3DownloadResponse {
  message?: string;
  getUrl?: string;
}

export async function uploadToS3(file: File): Promise<S3UploadResponse> {
  const putUrlResponse = await fetch(
    `/${process.env.ENV_NAME?.toLowerCase()}/upload/put-url?fileType=${
      file.type
    }&fileName=${file.name}`,
    {
      method: 'GET',
    },
  );

  if (putUrlResponse.status !== 200)
    return { message: 'Failed to retrieve presigned url from lambda.' };

  const { signedPutUrl, s3Key } = await putUrlResponse.json();

  const putResponse = await fetch(signedPutUrl, {
    method: 'PUT',
    headers: new Headers({ 'Content-Type': file.type, credentials: 'include' }),
    body: file,
  });

  if (putResponse.status !== 200)
    return { message: 'Failed to upload file to S3.' };

  const objectInfoResponse = await fetch(
    `/${process.env.ENV_NAME?.toLowerCase()}/upload/info?key=${s3Key}`,
    {
      method: 'GET',
    },
  );
  const { lastModifiedDate } = await objectInfoResponse.json();
  return {
    s3Key,
    lastModifiedDate: lastModifiedDate || '',
    fileName: file.name,
  };
}

export async function downloadFromS3(key: string): Promise<S3DownloadResponse> {
  const getUrlResponse = await fetch(
    `/${process.env.ENV_NAME?.toLowerCase()}/upload/get-url?key=${key}`,
    {
      method: 'GET',
    },
  );

  if (getUrlResponse.status !== 200)
    return Promise.resolve({
      message: 'Failed to retrieve presigned url from lambda.',
    });

  const { signedGetUrl } = await getUrlResponse.json();

  return Promise.resolve({
    getUrl: signedGetUrl,
  });
}

export const formatISODate = (date: string): string =>
  date.replace('T', ' ').split('.')[0];
