/* eslint-disable no-bitwise */
import remove from 'lodash/remove';
import each from 'lodash/each';
import ConsoleLogProvider from './ConsoleLogProvider';

export interface LogProvider {
  log(
    severity: logTypes,
    message: string,
    additionalInfo?: unknown,
    stackTrace?: string,
  ): void;
}

export enum logTypes {
  FATAL = 1,
  ERROR = 2,
  WARN = 4,
  INFO = 8,
  DEBUG = 16,
  TRACE = 32,
  USAGE = 64,
}

// Note that the below use bitwise ORs
export enum logLevels {
  NONE = 0,
  ALL = logTypes.FATAL |
    logTypes.ERROR |
    logTypes.WARN |
    logTypes.INFO |
    logTypes.DEBUG |
    logTypes.TRACE,
  DEFAULT = logTypes.FATAL | logTypes.ERROR,
}

interface LogProviderObject {
  provider: LogProvider;
  id: string;
  level: logLevels;
}

export default class Logger {
  logProviders: Array<LogProviderObject>;
  logLevel: number;

  constructor() {
    this.logProviders = [];
    this.logLevel = 32;

    this.addLogProvider(new ConsoleLogProvider(), 'console', logLevels.ALL);
  }

  addLogProvider(
    logProvider: LogProvider,
    logProviderId: string,
    logLevel: logLevels,
  ): void {
    const providerObject: LogProviderObject = {
      provider: logProvider,
      id: logProviderId,
      level: logLevel,
    };
    this.logProviders.push(providerObject);
  }

  removeLogProvider(logProviderId: string): void {
    remove(
      this.logProviders,
      (logProvider) => logProvider.id === logProviderId,
    );
  }

  removeAllLogProviders(): void {
    remove(this.logProviders, () => true);
  }

  log(
    severity: logTypes,
    message: string,
    additionalInfo?: unknown,
    stackTrace?: string,
  ): void {
    switch (severity) {
      case 1:
        this.fatal(message, additionalInfo, stackTrace);
        break;
      case 2:
        this.error(message, additionalInfo, stackTrace);
        break;
      case 4:
        this.warn(message, additionalInfo, stackTrace);
        break;
      case 8:
        this.info(message, additionalInfo, stackTrace);
        break;
      case 16:
        this.debug(message, additionalInfo, stackTrace);
        break;
      case 32:
        this.trace(message, additionalInfo, stackTrace);
        break;
      case 64:
        this.usage(message, additionalInfo, stackTrace);
        break;
      default:
        // Throw? or info?
        break;
    }
  }

  error(message: string, additionalInfo?: unknown, stackTrace?: string): void {
    this.logInternal(logTypes.ERROR, message, additionalInfo, stackTrace);
  }

  fatal(message: string, additionalInfo?: unknown, stackTrace?: string): void {
    this.logInternal(logTypes.FATAL, message, additionalInfo, stackTrace);
    // Do more?
  }

  warn(message: string, additionalInfo?: unknown, stackTrace?: string): void {
    this.logInternal(logTypes.WARN, message, additionalInfo, stackTrace);
  }

  info(message: string, additionalInfo?: unknown, stackTrace?: string): void {
    this.logInternal(logTypes.INFO, message, additionalInfo, stackTrace);
  }

  debug(message: string, additionalInfo?: unknown, stackTrace?: string): void {
    this.logInternal(logTypes.DEBUG, message, additionalInfo, stackTrace);
  }

  trace(message: string, additionalInfo?: unknown, stackTrace?: string): void {
    this.logInternal(logTypes.TRACE, message, additionalInfo, stackTrace);
  }

  usage(message: string, additionalInfo?: unknown, stackTrace?: string): void {
    this.logInternal(logTypes.USAGE, message, additionalInfo, stackTrace);
  }

  logInternal(
    severity: logTypes,
    message: string,
    additionalInfo?: unknown,
    stackTrace?: string,
  ): void {
    each(this.logProviders, (providerContainer) => {
      // Note that the below is a bitwise AND
      if (providerContainer.level & severity) {
        providerContainer.provider.log(
          severity,
          message,
          additionalInfo,
          stackTrace,
        );
      }
    });
  }
}
