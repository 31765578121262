import axios from 'axios';
import Utils from '@/js/utils.js';
import Vue from 'vue';

export default {
  async delete(requestURL) {
    console.log('DELETE', requestURL);
    try {
      let config = {
        headers: {},
      };

      if (process.env.VUE_APP_REQUIRES_AUTH !== 'false')
        await this.addAuthHeaders(config);

      const response = await axios.delete(requestURL, config);
      console.log(response.data);
      return response.data;
    } catch (error) {
      //TODO: Catch And Print "message": "Forbidden
      return this.handleError(error);
    }
  },
  async get(requestURL, parameters, refresh = false) {
    console.log('GET', requestURL, parameters, refresh ? 'refresh' : '');

    try {
      let config = {
        headers: {},
        params: parameters,
      };

      if (process.env.VUE_APP_REQUIRES_AUTH !== 'false')
        await this.addAuthHeaders(config);

      if (refresh) {
        config['headers']['Cache-Control'] = 'max-age=0';
      }
      const response = await axios.get(requestURL, config);
      console.log(response.data);
      return response.data;
    } catch (error) {
      return this.handleError(error);
    }
  },
  async post(requestURL, parameters) {
    console.log('POST', requestURL, parameters);
    try {
      let config = {
        headers: {},
      };

      if (process.env.VUE_APP_REQUIRES_AUTH !== 'false')
        await this.addAuthHeaders(config);

      const response = await axios.post(
        requestURL,
        JSON.stringify(parameters),
        config,
      );

      console.log(response.data);
      return response.data;
    } catch (error) {
      return this.handleError(error);
    }
  },
  async put(requestURL, parameters) {
    console.log('PUT', requestURL, parameters);
    try {
      let config = {
        headers: {},
      };

      if (process.env.VUE_APP_REQUIRES_AUTH !== 'false')
        await this.addAuthHeaders(config);

      const response = await axios.put(
        requestURL,
        JSON.stringify(parameters),
        config,
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return this.handleError(error);
    }
  },
  handleError(error) {
    if (error.hasOwnProperty('response') && error.response) {
      let errorMessage = error.response.data.errorMessage;
      const errorType = error.response.data.errorType;
      Utils.methods.notifyError(errorMessage, errorType);
    } else if (error.hasOwnProperty('message') && error.message) {
      console.log(Object.keys(error));
      console.log('response', error['response']);
      Utils.methods.notifyError(error.message);
    }
    return undefined;
  },
  async addAuthHeaders(config) {
    config['headers']['X-Api-Key'] = process.env.VUE_APP_API_KEY;
    config['headers'][
      'Authorization'
    ] = `Bearer ${await Vue.prototype.$auth.getAccessToken()}`;
  },
};
