<template>
  <span>
    <template v-if="singleValue">
      <span v-if="type === 'DateTime'"> {{ value.replace('T', ' ') }}</span>
      <a v-if="type === 'URL'" :href="value"> {{ value }}</a>
      <span v-if="singleValue && type !== 'URL' && type !== 'DateTime'">
        {{ value }}
      </span>
    </template>
    <template v-if="!singleValue">
      <span v-if="name === 'popularity' || name === 'popularity180'">
        {{ value[value.length - 1] }}
      </span>
      <span v-if="name === 'translation'">
        <mds-button
          class="link"
          variation="flat"
          @click="toggleTranslationDiv()"
          >Click to view</mds-button
        >
        <div class="mds-doc-typography" v-if="isTranslationShow">
          <ul style="list-style: none">
            <li class="hide-marker">
              <ul
                style="list-style: none"
                v-for="item in buildTranslationList(value)"
                :key="`${item}`"
              >
                <li>{{ item }}</li>
              </ul>
            </li>
          </ul>
        </div>
      </span>
      <span v-else>
        {{ value.join(', ') }}
      </span>
    </template>
  </span>
</template>

<script>
import _ from 'underscore';
import { MdsButton } from '@mds/button';

export default {
  //Test comment
  name: 'TermViewAttribute',
  components: {
    MdsButton,
  },
  props: {
    value: [Boolean, Number, String, Array],
    type: String,
    singleValue: Boolean,
    name: String,
  },
  data() {
    return {
      isTranslationShow: false,
    };
  },
  methods: {
    buildTranslationList(values) {
      const dictValues = {};
      values = _.sortBy(values, i => i.toLowerCase());
      values.forEach(value => {
        const parts = value.split(';', 2);
        const prefix = parts[0];
        const suffix = parts[1];
        if (dictValues.hasOwnProperty(prefix)) {
          let prevValue = dictValues[prefix];
          dictValues[prefix] = prevValue + ', ' + suffix;
        } else dictValues[prefix] = suffix;
      });

      return Array.from(Object.keys(dictValues), i => {
        return `${i}: ${dictValues[i]}`;
      });
    },
    toggleTranslationDiv() {
      this.isTranslationShow = !this.isTranslationShow;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/style/global.scss';
.mds-doc-typography ul {
  line-height: 26px;
  list-style: none;
  margin: 0;
  padding-left: 28px;
  position: relative;
  margin-bottom: 4px;
  max-width: 800px;
}
.mds-doc-typography ul ul {
  list-style-type: none;
  margin: 0;
  padding-left: 28px;
  position: relative;
}
.mds-doc-typography ul li::before {
  color: #ababab;
  content: '\2022';
  font-size: 16px;
  left: 0;
  line-height: 26px;
  position: absolute;
  vertical-align: baseline;
}
.hide-marker::before {
  display: none;
}
.mds-doc-typography ul li ul li::before {
  content: '\25CB';
  font-size: 16px;
  left: 0;
  line-height: 26px;
  position: absolute;
  vertical-align: baseline;
}
.link {
  font-style: italic;
}
</style>
