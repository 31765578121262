<template>
  <div>
    <div v-if="!pageHasError">
      <mds-loader class="searchedit_center" v-if="isLoading"></mds-loader>
      <div else style="display: flex; justify-content: space-between">
        <mds-button
          v-if="selectedTerm !== null"
          id="close-button"
          @click="selectedTerm = null"
          >Close</mds-button
        >
        <div :style="leftPanel" class="panel">
          <mds-form onsubmit="return false">
            <mds-section
              bold
              collapsible
              v-if="!isLoading"
              border="primary"
              title="How to Classify Content"
              class="primary-heading"
            >
              A keyword is a term that describes what an article is about.
              Keywords help us organize content, so users can find relevant
              information. Select one or more terms that describe the focus of
              the article. If applicable, add the main idea (concept),
              companies, events, funds, fund managers, and fund families
              discussed. Consider using a keyword when:
              <ul>
                <li>
                  The topic is included in the title or deck of the article.
                </li>
                <li>
                  The topic is included in the subhead of an article section.
                </li>
                <li>
                  The topic is found many times within the body of the article.
                </li>
              </ul>
              <h4>
                Can't find the term you need?
                <a class="my-link" :href="mailtoLink">Suggest a Term</a> or
                email keywords@morningstar.com
              </h4>
              <h4>
                For detailed guidelines, go to the training document in
                <a target="_blank" class="my-link" :href="screenStepsLink"
                  >ScreenSteps</a
                >
              </h4>
            </mds-section>
            <TermEditRelationship
              v-for="relationship in publicRelationshipSchema"
              :key="`${relationship.name}${relationship.targetClass}`"
              :existingTaxonomy="{ taxonomy }"
              v-bind="relationship"
              @change="relationshipChanged(relationship, $event)"
              @route="route($event)"
              ref="relationships"
              :powerup="true"
              :classDescription="getDescription(relationship.targetClass)"
            />
            <mds-section
              v-if="!isLoading"
              id="classification__section"
              collapsible
              border="none"
              @mds-section-expanded="output"
              :size="7"
              title="For classifiers use only"
              class="primary-heading"
            >
              <TermEditAttribute
                v-for="(attribute, attributeIdx) in attributeSchema"
                v-bind="attribute"
                :key="`${attribute.name}${attributeIdx}`"
                @change="attributeChanged(attribute, $event)"
                ref="attributes"
              />
              <TermEditRelationship
                v-for="relationship in privateRelationshipSchema"
                :key="`${relationship.name}${relationship.targetClass}`"
                :existingTaxonomy="{ taxonomy }"
                v-bind="relationship"
                @change="relationshipChanged(relationship, $event)"
                @route="route($event)"
                ref="relationships"
                :powerup="true"
                :classDescription="getDescription(relationship.targetClass)"
              />
            </mds-section>
          </mds-form>
        </div>
        <div :style="rightPanel">
          <PowerupTermView
            v-if="selectedTerm !== null"
            :termId="selectedTerm.id"
            :debug="debug"
          />
        </div>
        <mds-button-container id="button-container">
          <mds-button
            variation="primary"
            type="button"
            :disabled="!isDirty || isSubmiting"
            :loading="isSubmiting"
            @click="submit"
          >
            Submit
          </mds-button>
          <mds-button :disabled="isSubmiting" @click="cancel">
            Cancel
          </mds-button>
        </mds-button-container>
      </div>
    </div>
    <div v-else class="searchedit_center">
      There was an error loading the page. Please ensure that you are on the
      VPN.
    </div>
  </div>
</template>

<script>
import {
  sendCancelMessage,
  sendDataMessage,
  parseURLQuery,
  log,
  classes,
  classDescriptions,
} from '@/js/powerup.js';
import { MdsButton, MdsButtonContainer } from '@mds/button';
import MdsForm from '@mds/form';
import MdsLoader from '@mds/loader';
import MdsSection from '@mds/section';
import PowerupTermView from './PowerupUpTermViewComponent.vue';
import TermEditRelationship from '../TermManagement/TermEditRelationship.vue';
import TermEditAttribute from '../TermManagement/TermEditAttribute.vue';

export default {
  name: 'PowerupSearchComponent',
  components: {
    MdsButton,
    MdsButtonContainer,
    MdsForm,
    MdsLoader,
    MdsSection,
    TermEditRelationship,
    TermEditAttribute,
    PowerupTermView,
  },
  /** lifecycle: beforeCreate, created, beforeMount, mounted, beforeUpdate, updated, beforeUnmount, unmounted */
  /**
   * 1. If url query contains debug=true, set this.debug to true (to enable debug logging).
   * 2. If path is '/taxonomy/edit' then set this.edit to true.
   */
  created: async function () {
    if (this.$route.query.debug !== undefined) {
      if (this.$route.query.debug === 'true') {
        this.debug = true;
        log('DEBUG is on.', 'info');
      }
    }
    if (this.debug) log('created', 'life');
    if (this.debug) log(`route: ${this.$route.path}`, 'debug');
    if (this.$route.path === '/taxonomy/edit') {
      if (this.debug) log('this.edit == true');
      this.edit = true;
    }

    // check for k
    if (this.$route.query.k === undefined) {
      if (this.debug)
        log('query does not contain the proper query identifier (k)', 'warn');
    } else {
      this.k = this.$route.query.k;
    }

    // if page is edit, check for p
    if (this.edit && this.$route.query.p === undefined) {
      if (this.debug)
        log(
          'query does not contain the proper query identifier for /taxonomy/edit/ (p)',
          'warn',
        );
    } else {
      this.p = this.$route.query.p;
    }
  },
  /**
   * 1. call getAllClasses so that isAbout relationships can be populated.
   * 2. populate this.relationshipSchema with the isAbout relationships
   * 3. If this.edit is true, try to parse the URL.
   */
  beforeMount: async function () {
    if (this.debug) log('beforeMount', 'life');
    if (this.debug) log('getAllClasses()', 'debug');
    await this.$store.dispatch('getAllClasses');
    try {
      let classOptions = [];
      classOptions =
        this.$store.state.schema.classMap['article']['relationships'];

      // load Attributes, we only need marketsArticleCategory
      this.attributeSchema =
        this.$store.state.schema.classMap['article']['attributes'];

      this.attributeSchema = this.attributeSchema.filter(
        attr => attr['name'] === 'marketsArticleCategory',
      );

      this.publicRelationshipSchema = classOptions.filter(function (value) {
        return (
          value['name'] == 'isAbout' &&
          classes.public.includes(value['targetClass'])
        );
      });
      this.privateRelationshipSchema = classOptions.filter(function (value) {
        return (
          value['name'] == 'isAbout' &&
          classes.private.includes(value['targetClass'])
        );
      });

      this.isLoading = false;
      this.publicRelationshipSchema.forEach(e => {
        this.classes.push(e['targetClass']);
      });
      this.privateRelationshipSchema.forEach(e => {
        this.classes.push(e['targetClass']);
      });
    } catch (error) {
      if (this.debug)
        log('The function getAllClasses has failed.', 'error', error);
      this.pageHasError = true;
    }
    if (this.edit) {
      this.isDirtyRelationship = false;
      this.isDirtyAttribute = false;

      let editData = parseURLQuery(this.$route.query, this.debug);

      Object.keys(editData).forEach(key => {
        if (key.startsWith('taxonomy_item_')) {
          if (editData[key]['taxonomy_class'] === 'attribute') {
            this.editAttributes[key] = editData[key];

            this.modifiedAttributes.push({
              name: editData[key]['taxonomy_id'],
              description: editData[key]['taxonomy_id'],
              value: editData[key]['taxonomy_name'],
            });
          } else this.taxonomy[key] = editData[key];
        }
      });

      // Load Attribute Values
      this.attributeSchema.forEach(attribute => {
        let targetAttribute = this.modifiedAttributes.find(
          a => a['name'] === attribute['name'],
        );

        if (targetAttribute && targetAttribute['value'])
          attribute['value'] = targetAttribute['value'];
      });

      if (this.debug) log('URL has been parsed', 'dir', editData);
      // if object is empty
      if (Object.keys(editData).length === 0)
        log('this.edit is true, but editData is empty', 'warn');
    }
    this.loading = false;
  },
  /**
   * Called after the page has been mounted.
   * document.documentElement.scrollHeight will only be accurate after the page has been mounted.
   * There is a style conflict between prettier and another linter that will not allow compilation if not ignored.
   * 1. Send a message to the parent that this page is ready.
   */
  // prettier-ignore
  mounted: async function() {
    if (this.debug) log('mounted', 'life');
  },
  data() {
    return {
      screenStepsLink:
        'https://morningstar.screenstepslive.com/a/1601405-classifying-articles-in-the-taxonomy-power-up',
      mailtoLink:
        'mailto:keywords@morningstar.com?subject=New Term Request&body=TERM:%0D%0APARENT TERM (if applicable):%0D%0ATITLE OF ARTICLE/VIDEO IN WHICH THE NEW TERM IS DISCUSSED:%0D%0AOptional%0D%0ANOTES:',
      /** This is the term object id that is active in the editPanel. */
      selectedTerm: null,
      /** query identifier in the URL (...view?p=...) This is the encoded taxonomy object. */
      p: 'p',
      /** query identifier in the URL (...view?k=...) This is the ARC identifier ID. */
      k: 'k',
      /** if true, this will output console messages */
      debug: false,
      /** This component is for Search and Edit. If this is set to true, this is the edit functionality. */
      edit: false,
      /** will contain data if relationships have changed */
      modifiedRelationships: {},
      /** will contain data if attributes have changed */
      modifiedAttributes: [],
      /** used to show mds-loading */
      isLoading: true,
      /** if relationships have been changed at all */
      isDirtyRelationship: false,
      isDirtyAttribute: false,
      /** true if submit has been hit and page is posting to parent */
      isSubmiting: false,
      /** contains all of the classes */
      publicRelationshipSchema: [],
      /** These should be shown on the bottom */
      privateRelationshipSchema: [],
      /** contains a list of all the valid class types (i.e. people, organization, company, etc.) */
      attributeSchema: [],
      /** contains a list of all attributes (properties of the Article) */
      classes: [],
      /** the final object that is going to be passed to the parent window */
      finalObject: { id: '', url: '', config: {} },
      /** true if an error occurs */
      pageHasError: false,
      /** When the page is loaded by 'Edit' this will contain the taxonomy structure. This property name is what's being used on the Child Component */
      taxonomy: {},
      /** When the page is loaded by 'Edit' this will contain the taxonomy structure. */
      editAttributes: [],
    };
  },
  methods: {
    output() {
      let element = document.getElementById('classification__section');
      setTimeout(function () {
        element.scrollIntoView({ behavior: 'smooth' });
      }, 200);
    },
    /** This is the method called from PowerupTermViewComponent so that the selected term can be loaded in the term panel. */
    route(termObject) {
      this.selectedTerm = termObject;
    },
    /**
     * ADDING SOMETHING HERE WILL NEVER CHANGE TermEditRelationship, because this is the last step
     * This runs everytime something is added or removed (like a concept or company).
     * When this function runs, it emits to change() in TermEditRelationship.
     * The only two properties that matter in 'relationship' parameter are name and targetClass.
     * @param {object} relationship {"targetClass":"concept","name":"isAbout","nameInTarget":"discussedIn","description":"Is About","descriptionInTarget":"Discussed In","required":false,"requiredInTarget":false,"orderInTarget":3,"readOnly":false,"readOnlyInTarget":false,"order":2}
     * @param {object} newValue {"relationships_add":[{"name":"Investments","id":"concept-1","label":"concept"}],"relationships_delete":[]}
     */
    relationshipChanged(relationship, newValue) {
      const name = relationship.name; // this should always be isAbout in this context
      const targetClass = relationship.targetClass;

      // if this.selectedTerm exists on this.modifiedRelationships, then it should not be removed
      let found = false;
      if (this.selectedTerm !== null) {
        if (this.modifiedRelationships.hasOwnProperty('relationships_add')) {
          if (
            this.modifiedRelationships['relationships_add'].hasOwnProperty(name)
          ) {
            if (
              this.modifiedRelationships['relationships_add'][
                name
              ].hasOwnProperty(this.selectedTerm.label)
            )
              this.modifiedRelationships['relationships_add'][name][
                this.selectedTerm.label
              ].forEach(r => {
                if (r.id === this.selectedTerm.id) {
                  found = true;
                }
              });
          }
        }
      }
      if (!found) this.selectedTerm = null;

      if (Object.keys(newValue['relationships_add']).length > 0) {
        if (!this.modifiedRelationships.hasOwnProperty('relationships_add'))
          this.modifiedRelationships['relationships_add'] = {};
        if (
          !this.modifiedRelationships['relationships_add'].hasOwnProperty(name)
        )
          this.modifiedRelationships['relationships_add'][name] = {};
        this.modifiedRelationships['relationships_add'][name][targetClass] =
          newValue['relationships_add'];
      }

      this.isDirtyRelationship = true;
    },
    attributeChanged(attribute, newValue) {
      if (!attribute) return;

      const attrIndex = this.modifiedAttributes.find(attr => {
        return attr['name'] === attribute['name'];
      });

      if (attrIndex && attrIndex != -1) {
        const targetAttr = this.modifiedAttributes.find(attr => {
          return attr['name'] === attribute['name'];
        });
        targetAttr['value'] = newValue;
        this.modifiedAttributes.splice(attrIndex, 1, targetAttr);
      } else {
        this.modifiedAttributes.push({
          name: attribute['name'],
          description: attribute['description'],
          value: newValue,
        });
      }

      this.isDirtyAttribute = true;
    },
    /**
     * Runs on submit
     */
    async submit() {
      if (this.debug) log('SUBMIT has been pressed.', 'info');

      const hasAttributes =
        this.modifiedAttributes !== undefined &&
        this.modifiedAttributes.length > 0;

      const hasRelationships =
        this.modifiedRelationships !== undefined &&
        this.modifiedRelationships.hasOwnProperty('relationships_add') &&
        this.modifiedRelationships['relationships_add'].hasOwnProperty(
          'isAbout',
        );

      try {
        if (this.debug) {
          if (hasRelationships)
            log(
              'The object before being changed: ',
              'dir',
              this.modifiedRelationships['relationships_add']['isAbout'],
            );

          if (hasAttributes)
            log(
              'The object before being changed: ',
              'dir',
              this.modifiedAttributes,
            );
        }

        const taxonomyItems = {};
        let index = 0;

        if (hasAttributes) {
          this.modifiedAttributes.forEach(attribute => {
            if (attribute['value'])
              taxonomyItems[`taxonomy_item_${index++}`] = {
                ['taxonomy_name']: attribute['value'],
                ['taxonomy_id']: attribute['name'],
                ['taxonomy_class']: 'attribute',
              };
          });
        }

        if (hasRelationships) {
          this.classes.forEach(value => {
            if (
              this.modifiedRelationships['relationships_add'][
                'isAbout'
              ].hasOwnProperty([value])
            ) {
              this.modifiedRelationships['relationships_add']['isAbout'][
                value
              ].forEach(relationship => {
                taxonomyItems[`taxonomy_item_${index++}`] = {
                  ['taxonomy_name']: relationship['name'],
                  ['taxonomy_id']: relationship['id'],
                  ['taxonomy_class']: relationship['label'],
                };
              });
            }
          });
        }

        this.finalObject.id = this.k;
        this.finalObject.url = '/';
        this.finalObject.config = taxonomyItems;

        sendDataMessage(new URL(location.href).pathname, this.finalObject);

        if (this.debug) {
          log(
            'The message has been posted to the parent',
            'dir',
            this.finalObject.config,
          );
        }
      } catch (error) {
        if (this.debug) {
          log(
            'There was a problem in posting to the parent window. The window will now close.',
            'error',
            error,
          );
        }
        this.pageHasError = true;
        sendCancelMessage(new URL(location.href).pathname);
      }
    },
    getDescription(targetClass) {
      return classDescriptions.get(targetClass);
    },
    cancel() {
      sendCancelMessage(new URL(location.href).pathname);
    },
  },
  computed: {
    /** Will be 50% if this.selectedTerm is not null, 100% otherwise. */
    leftPanel: function () {
      if (this.selectedTerm !== null) return { width: '50%' };
      else return { width: '100%' };
    },
    /** Will be 50% of this.selectedTerm is not null, 0 otherwise. */
    rightPanel: function () {
      if (this.selectedTerm !== null)
        return { width: '50%', padding: '20px 20px 45px 20px' };
      else return { width: '0', display: 'none' };
    },
    /** TODO: This will be a check on the whitelist? */
    authorized() {
      return true;
    },
    isDirty() {
      return this.isDirtyRelationship || this.isDirtyAttribute;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/style/global.scss';
@import '@mds/link';
body {
  @include mds-unordered-list(small);
}
body ul {
  margin: #{$mds-space-1-x};
}
body h4 {
  @include mds-body-text-s();
}
.my-link {
  @include mds-link();
}
.primary-heading {
  margin: #{$mds-space-2-x};
}
.scr-trigger-margin {
  margin-bottom: #{$mds-space-2-x};
}
.panel {
  padding-bottom: 45px;
}
.searchedit_center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
#close-button {
  position: absolute;
  right: 40px;
  top: 20px;
}
#button-container {
  box-shadow: 0px 2px 2px 3.5px rgba(0, 0, 0, 0.15);
  position: fixed;
  background: white;
  bottom: 0;
  width: 100%;
  padding: 10px 10px 0 10px;
}
</style>
