'use strict';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import forEach from 'lodash/forEach';

export default class HTMLUtilities {
  queryString: string;
  params: Record<string, string[]>;

  constructor(str?: string) {
    this.queryString =
      typeof str === 'undefined' ? document.location.search : str;
    this.params = this.parse(this.queryString);
  }

  parse(string: string): Record<string, string[]> {
    if (string && !isEmpty(string)) {
      const obj: Record<string, string[]> = {};
      const pairsToParse = string.replace('?', '').split('&');

      const pairs = map(pairsToParse, function (pair) {
        return pair.split('=');
      });

      forEach(pairs, function (pair) {
        obj[pair[0]] = obj[pair[0]] || [];
        obj[pair[0]].push(decodeURIComponent(pair[1].replace(/\+/g, '%20')));
      });

      return obj;
    } else {
      return {};
    }
  }

  toString(): string {
    if (this.isEmpty()) {
      return '';
    } else {
      const str = map(this.params, function (values, key) {
        return map(values, function (value) {
          return key + '=' + value;
        }).join('&');
      }).join('&');

      return '?' + str;
    }
  }

  get(key: string): string[] {
    return this.params[key];
  }

  set(key: string, value: string): void {
    this.params[key] = [value];
    return;
  }

  append(key: string, value: string): number {
    this.params[key] = this.params[key] || [];
    return this.params[key].push(value);
  }

  delete(key: string): boolean {
    return delete this.params[key];
  }

  deleteAll(): void {
    this.params = {};
    return;
  }

  isEmpty(): boolean {
    return isEmpty(this.params);
  }

  static GetIntegerQueryStringParameter(
    parameter: string,
    urlString?: string,
  ): number | null {
    const url = new HTMLUtilities(urlString);
    const queryStringArray = url.get(parameter);
    if (
      queryStringArray !== null &&
      queryStringArray !== undefined &&
      queryStringArray.length > 0
    ) {
      const queryStringInt = parseInt(queryStringArray[0]);
      if (!isNaN(queryStringInt)) {
        return queryStringInt;
      }
    }
    return null;
  }

  static GetStringQueryStringParameter(
    parameter: string,
    urlString?: string,
  ): string | null {
    const url = new HTMLUtilities(urlString);
    const queryStringArray = url.get(parameter);
    if (
      queryStringArray !== null &&
      queryStringArray !== undefined &&
      queryStringArray.length > 0
    ) {
      return queryStringArray[0];
    }
    return null;
  }
}
